import React from 'react';
import styles from './PurchaseModal.module.scss';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

type Props = {
  onClose: () => void;
  children: React.ReactNode;
};

export const PurchaseModal = ({ onClose, children }: Props) => {
  const handleModalClose = () => {
    onClose();
  };

  return (
    <div className={styles.purchaseContainer}>
      <IconButton onClick={handleModalClose} className={styles.closeButton}>
        <CloseIcon />
      </IconButton>

      {children}
    </div>
  );
};

export default PurchaseModal;
