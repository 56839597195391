import axios from '../../config/Axios/axios-instance';
import {
  createReservationFail,
  createReservationsFail,
  createReservationsStart,
  createReservationsSuccess,
  createReservationStart,
  createReservationSuccess,
  deleteReservationFail,
  deleteReservationStart,
  deleteReservationSuccess,
  cancelReservationFail,
  cancelReservationStart,
  cancelReservationSuccess,
  fetchUserReservationsListFailed,
  fetchUserReservationsListStart,
  fetchUserReservationsListSuccess,
  resetReservationStore,
} from './actions';
import { Dispatch } from 'redux';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';
import { UserCreateRequest } from '../user/service';
import { ListParams } from 'src/hooks/useList/useList';

const API_URL = '/reservations';

export type ReservationCreateRequest = {
  slotId: string;
  userId?: number;
  user?: UserCreateRequest;
  finalPrice: number;
  notify: boolean;
};

type PublicReservation = {
  slotId: number;
  finalPrice: number;
  isPurchasedWithCredits: boolean;
};

export type ReservationsCreateRequest = {
  reservations: PublicReservation[];
};

export type ReservationDeleteRequest = {
  id: number;
  isRefund: boolean;
};

export type ReservationCancelRequest = {
  id: number;
};

export const createReservation =
  (inputs: ReservationCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createReservationStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createReservationSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.RESERVATION_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createReservationFail(err.response.data.message));
      });
  };

export const createReservations =
  (body: ReservationsCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createReservationsStart());
    return axios
      .post(`${API_URL}/public`, {
        ...body,
      })
      .then((response) => {
        dispatch(createReservationsSuccess(response.data));

        const isPurchasedWithCredits = body.reservations.some(
          (reservation) => reservation.isPurchasedWithCredits,
        );

        if (isPurchasedWithCredits) {
          showToast(translate(intl, 'SUCCESS.RESERVATION_CREATE'), 'success');
        }
      })
      .catch((err) => {
        dispatch(createReservationsFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const deleteReservation =
  (inputs: ReservationDeleteRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    const { id, ...body } = inputs;
    dispatch(deleteReservationStart());
    return axios
      .post(`${API_URL}/${id}/delete`, { ...body })
      .then(() => {
        dispatch(deleteReservationSuccess());
        showToast(translate(intl, 'SUCCESS.RESERVATION_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteReservationFail(err.response.data.message));
      });
  };

export const cancelReservation =
  (inputs: ReservationCancelRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    const { id } = inputs;
    dispatch(cancelReservationStart());
    return axios
      .post(`${API_URL}/${id}/cancel`)
      .then(() => {
        dispatch(cancelReservationSuccess());
        showToast(translate(intl, 'SUCCESS.RESERVATION_CANCEL'), 'success');
      })
      .catch((err) => {
        dispatch(cancelReservationFail(err.response.data.message));
      });
  };

export const fetchUserReservationsList =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchUserReservationsListStart());
    return axios
      .get(`${API_URL}/user-reservations`, { params })
      .then((response) => {
        dispatch(fetchUserReservationsListSuccess(response.data));
      })
      .catch((err) =>
        dispatch(fetchUserReservationsListFailed(err.response.data.message)),
      );
  };

export const resetReservations = () => (dispatch: Dispatch) => {
  dispatch(resetReservationStore());
};
