export const FETCH_ITEMS_START = 'FETCH_ITEMS_START';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAIL = 'FETCH_ITEMS_FAIL';

export const FETCH_PUBLIC_SHOP_ITEMS_START = 'FETCH_PUBLIC_SHOP_ITEMS_START';
export const FETCH_PUBLIC_SHOP_ITEMS_SUCCESS =
  'FETCH_PUBLIC_SHOP_ITEMS_SUCCESS';
export const FETCH_PUBLIC_SHOP_ITEMS_FAIL = 'FETCH_PUBLIC_SHOP_ITEMS_FAIL';

export const FETCH_ITEM_START = 'FETCH_ITEM_START';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAIL = 'FETCH_ITEM_FAIL';

export const FETCH_RELATED_SHOP_ITEMS_START = 'FETCH_RELATED_SHOP_ITEMS_START';
export const FETCH_RELATED_SHOP_ITEMS_SUCCESS =
  'FETCH_RELATED_SHOP_ITEMS_SUCCESS';
export const FETCH_RELATED_SHOP_ITEMS_FAIL = 'FETCH_RELATED_SHOP_ITEMS_FAIL';

export const FETCH_PUBLIC_SHOP_ITEM_START = 'FETCH_PUBLIC_SHOP_ITEM_START';
export const FETCH_PUBLIC_SHOP_ITEM_SUCCESS = 'FETCH_PUBLIC_SHOP_ITEM_SUCCESS';
export const FETCH_PUBLIC_SHOP_ITEM_FAIL = 'FETCH_PUBLIC_SHOP_ITEM_FAIL';

export const CREATE_ITEM_START = 'CREATE_ITEM_START';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAIL = 'CREATE_ITEM_FAIL';

export const UPDATE_ITEM_START = 'UPDATE_ITEM_START';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAIL = 'UPDATE_ITEM_FAIL';

export const DELETE_ITEM_START = 'DELETE_ITEM_START';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAIL = 'DELETE_ITEM_FAIL';

export const RESET_RELATED_SHOP_ITEM_STORE = 'RESET_RELATED_SHOP_ITEM_STORE';
export const RESET_ITEM_STORE = 'RESET_ITEM_STORE';

export const LOGOUT = 'LOGOUT';

export type ItemActionTypes =
  | typeof FETCH_ITEMS_START
  | typeof FETCH_ITEMS_SUCCESS
  | typeof FETCH_ITEMS_FAIL
  | typeof FETCH_PUBLIC_SHOP_ITEMS_START
  | typeof FETCH_PUBLIC_SHOP_ITEMS_SUCCESS
  | typeof FETCH_PUBLIC_SHOP_ITEMS_FAIL
  | typeof FETCH_ITEM_START
  | typeof FETCH_ITEM_SUCCESS
  | typeof FETCH_ITEM_FAIL
  | typeof FETCH_RELATED_SHOP_ITEMS_START
  | typeof FETCH_RELATED_SHOP_ITEMS_SUCCESS
  | typeof FETCH_RELATED_SHOP_ITEMS_FAIL
  | typeof FETCH_PUBLIC_SHOP_ITEM_START
  | typeof FETCH_PUBLIC_SHOP_ITEM_SUCCESS
  | typeof FETCH_PUBLIC_SHOP_ITEM_FAIL
  | typeof CREATE_ITEM_START
  | typeof CREATE_ITEM_SUCCESS
  | typeof CREATE_ITEM_FAIL
  | typeof UPDATE_ITEM_START
  | typeof UPDATE_ITEM_SUCCESS
  | typeof UPDATE_ITEM_FAIL
  | typeof DELETE_ITEM_START
  | typeof DELETE_ITEM_SUCCESS
  | typeof DELETE_ITEM_FAIL
  | typeof RESET_RELATED_SHOP_ITEM_STORE
  | typeof RESET_ITEM_STORE
  | typeof LOGOUT;
