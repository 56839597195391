import _, { trim } from 'lodash';
import prettyBytes from 'pretty-bytes';
import mime from 'mime';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { translate } from '../messageTranslator/translate';
import { EntityTranslation } from '../../domain/EntityTranslation';

const MESSAGE_REQUIRED = 'This field cannot be empty';
const MESSAGE_EMAIL = 'This field must be a valid email address';
const MESSAGE_TIME = 'Invalid time';
const MESSAGE_STRING_AND_NUMBER =
  'This field must contain only numbers and letters';
const MESSAGE_DATE_INVALID = 'This field must be a valid date';
const MESSAGE_WHOLE_NUMBERS =
  'This field must contain only whole numbers (1, 20, 88, 179)';
const MESSAGE_PRICE_NUMBERS =
  'This field must contain only numbers which can be whole or float type (1, 20, 88 or 0.01, 9.99, 17.89)';
const MESSAGE_DECIMAL_NUMBERS =
  'This field must contain only decimal numbers (1.00, 0.99, 9.99, 100.81)';
const MESSAGE_SLUG = 'This field must contain only lowercase and dashes';

export type ValidationRule = {
  type: string;
  value?: string | string[] | number;
  parameter?: any;
};

export const getValidationError = (
  value: string | string[] | number | File | File[] | EntityTranslation[],
  rules: Array<ValidationRule> | undefined,
  intl: IntlShape,
): Array<string> =>
  _.compact(
    _.map(rules, (rule) => {
      const validator = validatorFactory(rule);
      const validationValue =
        validator &&
        validator(
          // @ts-ignore
          value instanceof File ||
            (Array.isArray(value) &&
              (value as File[]).find((val) => val instanceof File))
            ? Array.isArray(value)
              ? (value as File[]).filter((val) => val instanceof File)
              : value
            : value.toString(),
          intl,
          rule.parameter,
        );
      if (validationValue) {
        return validationValue;
      }
    }),
  );

const validatorFactory = (rule: ValidationRule) => {
  switch (rule.type) {
    case 'required':
      return requiredValidator;
    case 'minLength':
      return minLengthValidator;
    case 'maxLength':
      return maxLengthValidator;
    case 'email':
      return emailValidator;
    case 'numbersAndStrings':
      return numbersAndStringsValidator;
    case 'positiveNumbers':
      return positiveNumbersValidator;
    case 'min':
      return minValidator;
    case 'max':
      return maxValidator;
    case 'time':
      return timeValidator;
    case 'fileSize':
      return fileSizeValidator;
    case 'fileExtension':
      return fileExtensionValidator;
    case 'isValidDate':
      return isValidDate;
    case 'isValidSlug':
      return slugValidator;
  }
};

const requiredValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value.length === 0
    ? translate(intl, 'VALIDATION.REQUIRED', MESSAGE_REQUIRED)
    : undefined;

const minLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  value && length && value.length < length
    ? translate(
        intl,
        'VALIDATION.MIN_LENGTH',
        `This field must be more than ${length} symbols`,
      ).replace(':value', length.toString())
    : undefined;

const maxLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  length && value.length > length
    ? translate(
        intl,
        'VALIDATION.MAX_LENGTH',
        `This field must be less than ${length} symbols`,
      ).replace(':value', length.toString())
    : undefined;

const numbersAndStringsValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  /^[a-zA-Z0-9]*$/.test(trim(value.toString()))
    ? undefined
    : translate(
        intl,
        'VALIDATION.NUMBERS_AND_STRINGS',
        MESSAGE_STRING_AND_NUMBER,
      );

const positiveNumbersValidator = (
  value: string,
  intl: IntlShape,
  parameter?: 'isDecimal' | 'isPrice' | any,
): string | undefined => {
  /**
   * This function validates input value with @RegExp
   *
   * @param parameter 'isDecimal' if passed validates decimal number such as (100.11, 1.98, 55.50)
   * @param parameter 'isPrice' if passed validates whole and decimal number such as (0.01, 9.99, 55.50, or 1, 18, 1000)
   * @default Behavior Is to check only positive integers (0, 1, 9, 16, 99, 453, 888)
   *
   */

  if (value.length > 0) {
    let regexp;
    let validationMessage;

    switch (parameter) {
      case 'isDecimal':
        regexp = /^\d*\u002E\d{2}$/;
        validationMessage = translate(
          intl,
          'VALIDATION.DECIMAL_NUMBERS',
          MESSAGE_DECIMAL_NUMBERS,
        );
        break;

      case 'isPrice':
        regexp = /^(?!0\d)\d*(?:\u002E\d{2})?$/;
        validationMessage = translate(
          intl,
          'VALIDATION.PRICE_NUMBERS',
          MESSAGE_PRICE_NUMBERS,
        );
        break;

      default:
        regexp = /^\d*$/;
        validationMessage = translate(
          intl,
          'VALIDATION.WHOLE_NUMBERS',
          MESSAGE_WHOLE_NUMBERS,
        );
    }

    return regexp.test(trim(value.toString())) ? undefined : validationMessage;
  }

  return undefined;
};

const emailValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trim(value.toString()))
    ? undefined
    : translate(intl, 'VALIDATION.EMAIL', MESSAGE_EMAIL);

const minValidator = (
  value: string | string[],
  intl: IntlShape,
  parameter?: number,
): string | undefined =>
  (parameter || parameter === 0) && +value < parameter
    ? `${translate(
        intl,
        'VALIDATION.MIN',
        'This field must be more or equal',
      )} ${parameter}`
    : undefined;

const maxValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  length && +value > length
    ? `${translate(
        intl,
        'VALIDATION.MAX',
        'This field must be less than',
      )} ${length}`
    : undefined;

const timeValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(trim(value.toString()))
    ? undefined
    : translate(intl, 'VALIDATION.TIME', MESSAGE_TIME);

const fileSizeValidator = (
  value: string | string[],
  intl: IntlShape,
  size?: number,
): string | undefined => {
  const valuesToValidate = Array.isArray(value) ? value : [value];

  const errors = [];

  for (const val of valuesToValidate) {
    errors.push(fileSizeValidatorFn(val, intl, size));
  }

  return errors.filter((error) => error)?.[0];
};

const fileSizeValidatorFn = (
  value: string | string[],
  intl: IntlShape,
  size?: number,
): string | undefined => {
  const file = value as unknown as File;

  if (!(file instanceof File)) {
    return undefined;
  }

  return file && size && size > file.size
    ? undefined
    : `${translate(
        intl,
        'VALIDATION.MAX_FILE_SIZE',
        'File cannot be larger than',
      )} ${prettyBytes(size ?? 0)}`;
};

const fileExtensionValidator = (
  value: string | string[],
  intl: IntlShape,
  extensions?: string[],
): string | undefined => {
  const valuesToValidate = Array.isArray(value) ? value : [value];

  const errors = [];

  for (const val of valuesToValidate) {
    errors.push(fileExtensionValidatorFn(val, intl, extensions));
  }

  return errors.filter((error) => error)?.[0];
};

const fileExtensionValidatorFn = (
  value: string | string[],
  intl: IntlShape,
  extensions?: string[],
): string | undefined => {
  const file = value as unknown as File;

  if (!(file instanceof File)) {
    return undefined;
  }

  return value &&
    extensions &&
    extensions.includes(mime.getExtension(file.type) ?? '')
    ? undefined
    : `${translate(
        intl,
        'VALIDATION.FILE_TYPES',
        'File must be one of these types',
      )}: ${extensions?.toString().replace(',', ', ')}`;
};

const isValidDate = (value: string | string[]): string | undefined =>
  value === '' || moment(value).isValid() ? undefined : MESSAGE_DATE_INVALID;

const slugValidator = (
  value: string | string[] | undefined | null,
  intl: IntlShape,
): string | undefined => {
  if (!value || value === '') {
    return undefined;
  }

  const slugRegex = /^[a-z-]+$/g;
  if (!slugRegex.test(trim(value.toString()))) {
    return translate(intl, 'VALIDATION.IS_VALID_SLUG', MESSAGE_SLUG);
  }
};
