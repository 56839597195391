export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL';

export const FETCH_PUBLIC_LOCATIONS_START = 'FETCH_PUBLIC_LOCATIONS_START';
export const FETCH_PUBLIC_LOCATIONS_SUCCESS = 'FETCH_PUBLIC_LOCATIONS_SUCCESS';
export const FETCH_PUBLIC_LOCATIONS_FAIL = 'FETCH_PUBLIC_LOCATIONS_FAIL';

export const FETCH_LOCATION_START = 'FETCH_LOCATION_START';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAIL = 'FETCH_LOCATION_FAIL';

export const CREATE_LOCATION_START = 'CREATE_LOCATION_START';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';

export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

export const RESET_LOCATION_STORE = 'RESET_LOCATION_STORE';

export const LOGOUT = 'LOGOUT';

export type LocationActionTypes =
  | typeof FETCH_LOCATIONS_START
  | typeof FETCH_LOCATIONS_SUCCESS
  | typeof FETCH_LOCATIONS_FAIL
  | typeof FETCH_PUBLIC_LOCATIONS_START
  | typeof FETCH_PUBLIC_LOCATIONS_SUCCESS
  | typeof FETCH_PUBLIC_LOCATIONS_FAIL
  | typeof FETCH_LOCATION_START
  | typeof FETCH_LOCATION_SUCCESS
  | typeof FETCH_LOCATION_FAIL
  | typeof CREATE_LOCATION_START
  | typeof CREATE_LOCATION_SUCCESS
  | typeof CREATE_LOCATION_FAIL
  | typeof UPDATE_LOCATION_START
  | typeof UPDATE_LOCATION_SUCCESS
  | typeof UPDATE_LOCATION_FAIL
  | typeof DELETE_LOCATION_START
  | typeof DELETE_LOCATION_SUCCESS
  | typeof DELETE_LOCATION_FAIL
  | typeof RESET_LOCATION_STORE
  | typeof LOGOUT;
