import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from 'src/common/List/List';
import { Order } from 'src/domain/Order';

export const createOrderStart = () => ({
  type: actionTypes.CREATE_ORDER_START,
});

export const createOrderSuccess = (createdOrder: Order) => ({
  type: actionTypes.CREATE_ORDER_SUCCESS,
  createdOrder,
});

export const createOrderFail = (orderCreateError: HttpError) => ({
  type: actionTypes.CREATE_ORDER_FAIL,
  orderCreateError,
});

export const resetOrderStore = () => ({
  type: actionTypes.RESET_ORDER_STORE,
});

export const fetchOrdersStart = () => ({
  type: actionTypes.FETCH_ORDERS_START,
});

export const fetchOrdersSuccess = (ordersList: ListResults<Order>) => ({
  type: actionTypes.FETCH_ORDERS_SUCCESS,
  ordersList,
});

export const fetchOrdersFail = (ordersListError: string) => ({
  type: actionTypes.FETCH_ORDERS_FAIL,
  ordersListError,
});

export const fetchUserOrdersStart = () => ({
  type: actionTypes.FETCH_USER_ORDERS_START,
});

export const fetchUserOrdersSuccess = (userOrdersList: ListResults<Order>) => ({
  type: actionTypes.FETCH_USER_ORDERS_SUCCESS,
  userOrdersList,
});

export const fetchUserOrdersFail = (userOrdersListError: string) => ({
  type: actionTypes.FETCH_USER_ORDERS_FAIL,
  userOrdersListError,
});
