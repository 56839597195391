export const FETCH_PAYMENT_START = 'FETCH_PAYMENT_START';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_FAIL = 'FETCH_PAYMENT_FAIL';

export const FETCH_PAYMENT_LIST_START = 'FETCH_PAYMENT_LIST_START';
export const FETCH_PAYMENT_LIST_SUCCESS = 'FETCH_PAYMENT_LIST_SUCCESS';
export const FETCH_PAYMENT_LIST_FAIL = 'FETCH_PAYMENT_LIST_FAIL';

export const CREATE_TOP_UP_START = 'CREATE_TOP_UP_START';
export const CREATE_TOP_UP_SUCCESS = 'CREATE_TOP_UP_SUCCESS';
export const CREATE_TOP_UP_FAIL = 'CREATE_TOP_UP_FAIL';

export const CREATE_DIRECT_PAYMENT_START = 'CREATE_DIRECT_PAYMENT_START';
export const CREATE_DIRECT_PAYMENT_SUCCESS = 'CREATE_DIRECT_PAYMENT_SUCCESS';
export const CREATE_DIRECT_PAYMENT_FAIL = 'CREATE_DIRECT_PAYMENT_FAIL';

export const EXPORT_PAYMENTS_START = 'EXPORT_PAYMENTS_START';
export const EXPORT_PAYMENTS_SUCCESS = 'EXPORT_PAYMENTS_SUCCESS';
export const EXPORT_PAYMENTS_FAIL = 'EXPORT_PAYMENTS_FAIL';

export const SET_IS_PAYMENT_STATUS_SUCCESS = 'SET_IS_PAYMENT_STATUS_SUCCESS';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';

export const RESET_EXPORT_PAYMENTS = 'RESET_EXPORT_PAYMENTS';
export const RESET_TOP_UP_SUCCESS_STATUS = 'RESET_TOP_UP_SUCCESS_STATUS';
export const RESET_DIRECT_PAYMENT_SUCCESS_STATUS =
  'RESET_DIRECT_PAYMENT_SUCCESS_STATUS';
export const RESET_PAYMENT_STATUS = 'RESET_PAYMENT_STATUS';
export const RESET_PAYMENT_STORE = 'RESET_PAYMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENT_START
  | typeof FETCH_PAYMENT_SUCCESS
  | typeof FETCH_PAYMENT_FAIL
  | typeof FETCH_PAYMENT_LIST_START
  | typeof FETCH_PAYMENT_LIST_SUCCESS
  | typeof FETCH_PAYMENT_LIST_FAIL
  | typeof CREATE_TOP_UP_START
  | typeof CREATE_TOP_UP_SUCCESS
  | typeof CREATE_TOP_UP_FAIL
  | typeof CREATE_DIRECT_PAYMENT_START
  | typeof CREATE_DIRECT_PAYMENT_SUCCESS
  | typeof CREATE_DIRECT_PAYMENT_FAIL
  | typeof EXPORT_PAYMENTS_START
  | typeof EXPORT_PAYMENTS_SUCCESS
  | typeof EXPORT_PAYMENTS_FAIL
  | typeof RESET_EXPORT_PAYMENTS
  | typeof SET_IS_PAYMENT_STATUS_SUCCESS
  | typeof RESET_TOP_UP_SUCCESS_STATUS
  | typeof SET_PAYMENT_TYPE
  | typeof RESET_DIRECT_PAYMENT_SUCCESS_STATUS
  | typeof RESET_PAYMENT_STATUS
  | typeof RESET_PAYMENT_STORE
  | typeof LOGOUT;
