import * as actionTypes from './actionTypes';
import { OrderActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from 'src/common/List/List';
import { Order } from 'src/domain/Order';

export type OrderStateType = {
  orderCreateLoading: boolean;
  orderCreateError: HttpError;
  orderCreateSuccess: boolean;
  userUpdateNeeded: boolean;
  createdOrder: Order | null;
  ordersList: ListResults<Order> | null;
  ordersListLoading: boolean;
  ordersListError: HttpError;
  userOrdersList: ListResults<Order> | null;
  userOrdersListLoading: boolean;
  userOrdersListError: HttpError;
};

export type OrderActionType = OrderStateType & {
  type: OrderActionTypes;
};

export const initialState: OrderStateType = {
  ordersList: null,
  ordersListLoading: false,
  ordersListError: null,
  orderCreateLoading: false,
  orderCreateError: null,
  orderCreateSuccess: false,
  userUpdateNeeded: false,
  createdOrder: null,
  userOrdersList: null,
  userOrdersListError: null,
  userOrdersListLoading: false,
};

const createOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderCreateLoading: true,
});

const createOrderSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderCreateLoading: false,
  orderCreateSuccess: true,
  createdOrder: action.createdOrder,
  userUpdateNeeded: true,
});

const createOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderCreateLoading: false,
  orderCreateError: action.orderCreateError,
});

const resetOrderStore = (state: OrderStateType): OrderStateType => ({
  ...initialState,
});

const fetchOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  ordersListLoading: true,
});

const fetchOrdersSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersList: action.ordersList,
  ordersListLoading: false,
  ordersListError: null,
});

const fetchOrdersFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersListError: action.ordersListError,
  ordersListLoading: false,
});

const fetchUserOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  userOrdersListLoading: true,
});

const fetchUserOrdersSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  userOrdersList: action.userOrdersList,
  userOrdersListLoading: false,
  userOrdersListError: null,
});

const fetchUserOrdersFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  userOrdersListError: action.userOrdersListError,
  userOrdersListLoading: false,
});

const reducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS_START:
      return fetchOrdersStart(state);
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, action);
    case actionTypes.FETCH_ORDERS_FAIL:
      return fetchOrdersFail(state, action);
    case actionTypes.FETCH_USER_ORDERS_START:
      return fetchUserOrdersStart(state);
    case actionTypes.FETCH_USER_ORDERS_SUCCESS:
      return fetchUserOrdersSuccess(state, action);
    case actionTypes.FETCH_USER_ORDERS_FAIL:
      return fetchUserOrdersFail(state, action);
    case actionTypes.CREATE_ORDER_START:
      return createOrderStart(state);
    case actionTypes.CREATE_ORDER_SUCCESS:
      return createOrderSuccess(state, action);
    case actionTypes.CREATE_ORDER_FAIL:
      return createOrderFail(state, action);
    case actionTypes.RESET_ORDER_STORE:
      return resetOrderStore(state);
    default:
      return state;
  }
};

export default reducer;
