import React from 'react';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import styles from './PaymentResponse.module.scss';
import { Payment } from '../../../domain/Payment';

type Props = {
  payment: Payment;
  isSuccess: boolean;
};

export const PaymentResponse = ({ payment, isSuccess }: Props) => {
  const intl = useIntl();

  const getSubtitle = () => {
    if (isSuccess) {
      return translate(intl, 'PAYMENT.RESPONSE_SECTION_SUBTITLE_SUCCESS');
    }

    return translate(intl, 'PAYMENT.RESPONSE_SECTION_SUBTITLE_FAILED');
  };

  const getStatement = () => {
    if (isSuccess) {
      return `${translate(intl, 'PAYMENT.RESPONSE_SECTION_AMOUNT').replace(
        ':eurAmount',
        payment.price.toString(),
      )} ${translate(intl, 'PAYMENT.RESPONSE_SECTION_STATEMENT_SUCCESS')}`;
    }

    return translate(intl, 'PAYMENT.RESPONSE_SECTION_STATEMENT_FAILED');
  };

  return (
    <div className={styles.content}>
      <p className={styles.contentTitle}>
        {translate(intl, 'PAYMENT.RESPONSE_SECTION_TITLE')}
      </p>

      <p className={styles.contentSubTitle}>{getSubtitle()}</p>
      <p className={styles.contentStatement}>{getStatement()}</p>
    </div>
  );
};

export default PaymentResponse;
