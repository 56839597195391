import { Locale } from '../../domain/Translation';
import { Language } from '../../domain/Language';
import { Seo } from '../../domain/Seo';
import { routes } from '../../config/Router/routes';
import { getTranslationByKey } from '../domainTranslation/translator';

export const getLocaleUrl = (route: string, locale: Locale) =>
  route.replace(':locale', locale);

export const getNewSlugUrlToNavigate = (
  seo: Seo,
  selectedLocale: Locale,
  languages: Language[],
) => {
  const pathSegments = location.pathname.split('/');

  const contentSlug = pathSegments[3];

  const newContentSlug =
    getTranslationByKey('seo', seo?.id, selectedLocale, 'seoSlug', languages) ??
    seo?.slug;

  if (!newContentSlug) {
    return;
  }

  return location.pathname.replace(contentSlug, newContentSlug);
};

export const getItemSeoUrlToNavigate = (
  slug: string,
  seo: Seo | null,
  selectedLocale: Locale,
  languages: Language[],
) => {
  if (!seo) {
    return location.pathname;
  }

  return getLocaleUrl(routes.dynamicPageWithContent, selectedLocale)
    .replace(':slug', slug)
    .replace(
      ':contentSlug',
      getTranslationByKey(
        'seo',
        seo?.id,
        selectedLocale,
        'seoSlug',
        languages,
      ) ??
        seo?.slug ??
        '',
    )
    .toLowerCase();
};

export const navigateToUrl = (url: string) => {
  window.location.href = url;
  window.location.replace(url);
  return false;
};
