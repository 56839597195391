import React, { useEffect, useMemo } from 'react';
import styles from './EurPackageListModal.module.scss';
import packageStyles from '../EurPackageBlock/EurPackageBlock.module.scss';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { StoreState } from 'src/config/StoreProvider/StoreProvider';
import { EurPackage } from 'src/domain/EurPackage';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ListParams } from 'src/hooks/useList/useList';
import * as eurPackageService from '../../../store/eur-package/service';
import * as paymentService from '../../../store/payment/service';
import { ListResults } from 'src/common/List/List';
import EurPackageBlock from '../EurPackageBlock/EurPackageBlock';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { resetPublicEurPackagesList } from 'src/store/eur-package/actions';
import { useForm } from '../../../hooks/useForm/useForm';
import Form from '../../../common/Form/Form';
import TextField from '../../../common/TextField/TextField';
import Alert from '../../../common/Alert/Alert';
import { User } from 'src/domain/User';
import { HttpError } from '../../../config/Axios/axios-instance';
import Button from '../../../common/Button/Button';
import { ReactSVG } from 'react-svg';
import cartIcon from '../../../assets/icons/cart.svg';
import { TopUpCreateRequest } from '../../../store/payment/service';
import { PaymentType } from '../../../domain/Payment';
import { routes } from '../../../config/Router/routes';
import { useNavigate } from 'react-router-dom';
import { navigateToUrl } from '../../../utility/url/urlHelper';

type Props = {
  onCreate: (inputs: TopUpCreateRequest) => void;
  isLoading?: boolean;
  onSuccess?: () => void;
  isSuccess?: boolean;
  topUpSuccessRedirect: string | null;
  publicEurPackagesList: ListResults<EurPackage> | null;
  onPublicEurPackagesFetch: (params: ListParams) => void;
  onClose: () => void;
  onResetPublicEurPackagesList: () => void;
  user: User | null;
  error: HttpError;
};

type FormInputs = {
  eur: string;
};

export const EurPackageListModal = ({
  publicEurPackagesList,
  onPublicEurPackagesFetch,
  onClose,
  onResetPublicEurPackagesList,
  onCreate,
  isLoading,
  onSuccess,
  isSuccess,
  topUpSuccessRedirect,
  user,
  error,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const DEFAULT_LIST_PARAMS: ListParams = {
    sortBy: 'eurPackage.price',
    search: '',
    sortDirection: 'ASC',
    page: 0,
    limit: 0,
  };

  useEffect(() => {
    if (isSuccess && topUpSuccessRedirect) {
      onSuccess?.();
      navigateToUrl(topUpSuccessRedirect);
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => onResetPublicEurPackagesList();
  }, []);

  useEffect(() => {
    onPublicEurPackagesFetch(DEFAULT_LIST_PARAMS);
  }, []);

  const INPUTS = [
    {
      name: 'eur',
      label: translate(intl, 'EUR_PACKAGE.EUR'),
      type: 'text',
      isValidatable: true,
      validation: [
        { type: 'required' },
        { type: 'positiveNumbers' },
        { type: 'min', parameter: 1 },
        { type: 'max', parameter: 100000 },
      ],
      value: 100,
    },
  ];

  const handleSubmit = async (eurPackage: EurPackage) => {
    onCreate({
      eurPackageId: eurPackage.id,
      paymentType: PaymentType.BALANCE_TOP_UP,
    });
    localStorage.setItem('currentPage', window.location.pathname);
  };

  const handleCustomPackageSubmit = async () => {
    onCreate({
      customEurAmount: customEurAmount,
      paymentType: PaymentType.BALANCE_TOP_UP,
    });
    localStorage.setItem('currentPage', window.location.pathname);
  };

  const { inputs, onSubmit, onInputChange, onLoseInputFocus } =
    useForm<FormInputs>(INPUTS, handleCustomPackageSubmit);

  const customEurInput = inputs.find((input) => input.name === 'eur');
  const customEurAmount = customEurInput ? Number(customEurInput.value) : 0;

  const isButtonDisabled = (
    user: User | null,
    eurPackage: EurPackage | null,
    customEurAmount: number,
  ): boolean => {
    return (
      (!user?.profile || !user.profile.hasPurchasedPackage) &&
      (eurPackage ? eurPackage.eur < 100 : customEurAmount < 100)
    );
  };

  const hasMissingData = useMemo(() => {
    return (
      !user?.profile?.lastName ||
      !user?.profile?.firstName ||
      !user?.profile?.phone
    );
  }, [user]);

  return (
    <div className={styles.eurListContainer}>
      <IconButton onClick={onClose} className={styles.closeButton}>
        <CloseIcon />
      </IconButton>
      <div className={styles.details}>
        <div className={styles.category}>
          {translate(intl, 'PUBLIC_EUR_PACKAGE.NAME')}
        </div>
        <div className={styles.title}>
          {translate(intl, 'PUBLIC_EUR_PACKAGE.TITLE')}
        </div>
        <div className={styles.description}>
          {translate(intl, 'PUBLIC_EUR_PACKAGE.DESCRIPTION')}
        </div>
        {hasMissingData && (
          <div
            className={styles.missingData}
            onClick={() => {
              navigate(routes.profile.details, {
                replace: true,
                state: { displayWarning: true },
              });
              onClose();
            }}
          >
            {translate(intl, 'WARNING.FIRST_TIME_MESSAGE')}
          </div>
        )}
        <div className={styles.list}>
          {!hasMissingData && (
            <Form
              error={error}
              className={packageStyles.eurPackageContainer}
              onSubmit={onSubmit}
            >
              {isButtonDisabled(user, null, customEurAmount) && (
                <Alert variant="info" capitalize={false}>
                  {translate(intl, 'VALIDATION.EUR_PACKAGE_FIRST_PURCHASE')}
                </Alert>
              )}
              <div className={packageStyles.price}>
                {Number(
                  inputs.find((input) => input.name === 'eur')?.value || 0,
                )}
                &euro;
              </div>
              <div className={packageStyles.customEur}>
                {inputs
                  .filter((input) => ['eur'].includes(input.name))
                  .map((input) => (
                    <TextField
                      key={input.name}
                      name={input.name}
                      type={input.type}
                      onChange={onInputChange}
                      value={input.value?.toString() ?? ''}
                      label={input.label}
                      errors={input.validationErrors ?? []}
                      onBlur={onLoseInputFocus}
                    />
                  ))}
              </div>
              <div className={packageStyles.description}>
                <p>{translate(intl, 'EUR_PACKAGE.ENTER_CUSTOM_AMOUNT')}</p>
              </div>
              <div className={packageStyles.orderButton}>
                <Button
                  buttonVariant="contained"
                  color="secondary"
                  onClick={handleCustomPackageSubmit}
                  isLoading={isLoading}
                  isDisabled={isButtonDisabled(user, null, customEurAmount)}
                >
                  <span>{translate(intl, 'EUR_PACKAGE_BLOCK.BUY_EUR')}</span>
                  <ReactSVG className={packageStyles.cartIcon} src={cartIcon} />
                </Button>
              </div>
            </Form>
          )}
          {!hasMissingData && !!publicEurPackagesList?.result && (
            <>
              {publicEurPackagesList.result.map((publicEurPackage) => (
                <EurPackageBlock
                  key={publicEurPackage.id}
                  eurPackage={publicEurPackage}
                  user={user}
                  handleSubmit={handleSubmit}
                  isButtonDisabled={() =>
                    isButtonDisabled(user, publicEurPackage, customEurAmount)
                  }
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  publicEurPackagesList: state.eurPackage.publicEurPackagesList,
  error: state.eurPackage.eurPackageCreateError,
  isLoading: state.payment.topUpCreateLoading,
  isSuccess: state.payment.topUpCreateSuccess,
  topUpSuccessRedirect: state.payment.topUpSuccessRedirect,
  user: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onPublicEurPackagesFetch: (params: ListParams) =>
    dispatch(eurPackageService.fetchPublicEurPackages(params)),
  onResetPublicEurPackagesList: () => dispatch(resetPublicEurPackagesList),
  onCreate: (inputs: TopUpCreateRequest) =>
    dispatch(paymentService.createTopUp(inputs)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EurPackageListModal);
