import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import locationReducer, {
  LocationStateType,
} from '../../store/location/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';

import categoryReducer, {
  CategoryStateType,
} from '../../store/category/reducer';

import itemReducer, { ItemStateType } from '../../store/item/reducer';

import eurPackageReducer, {
  EurPackageStateType,
} from '../../store/eur-package/reducer';

import newsPostReducer, {
  NewsPostStateType,
} from '../../store/news-post/reducer';

import workingHourReducer, {
  WorkingHourStateType,
} from '../../store/working-hour/reducer';

import weekdayReducer, { WeekdayStateType } from '../../store/weekday/reducer';

import slotReducer, { SlotStateType } from '../../store/slot/reducer';

import reservationReducer, {
  ReservationStateType,
} from '../../store/reservation/reducer';

import itemVariantReducer, {
  ItemVariantStateType,
} from '../../store/itemVariant/reducer';

import itemVariantGroupReducer, {
  ItemVariantGroupStateType,
} from '../../store/itemVariantGroup/reducer';

import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import orderReducer, { OrderStateType } from '../../store/order/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import balanceHistoryReducer, {
  BalanceHistoryStateType,
} from '../../store/balance-history/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  location: LocationStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  category: CategoryStateType;
  item: ItemStateType;
  eurPackage: EurPackageStateType;
  newsPost: NewsPostStateType;
  workingHour: WorkingHourStateType;
  weekday: WeekdayStateType;
  slot: SlotStateType;
  reservation: ReservationStateType;
  itemVariant: ItemVariantStateType;
  itemVariantGroup: ItemVariantGroupStateType;
  order: OrderStateType;
  payment: PaymentStateType;
  balanceHistory: BalanceHistoryStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  location: locationReducer,
  translation: translationReducer,
  language: languageReducer,
  category: categoryReducer,
  item: itemReducer,
  eurPackage: eurPackageReducer,
  newsPost: newsPostReducer,
  workingHour: workingHourReducer,
  weekday: weekdayReducer,
  slot: slotReducer,
  reservation: reservationReducer,
  itemVariant: itemVariantReducer,
  itemVariantGroup: itemVariantGroupReducer,
  order: orderReducer,
  payment: paymentReducer,
  balanceHistory: balanceHistoryReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
