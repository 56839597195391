import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { CartItem } from 'src/domain/Cart';
import moment from 'moment';

export type UserStateType = {
  user: User | null;
  userLoading: boolean;
  userError: HttpError;
  usersList: ListResults<User> | null;
  usersLoading: boolean;
  usersError: HttpError;
  userCreateLoading: boolean;
  userCreateError: HttpError;
  userCreateSuccess: boolean;
  userUpdateLoading: boolean;
  userUpdateError: HttpError;
  userUpdateSuccess: boolean;
  userDeleteLoading: boolean;
  userDeleteError: HttpError;
  userDeleteSuccess: boolean;
  usersListUpdateNeeded: boolean;
  currentUser: User | null;
  currentUserLoading: boolean;
  allUsersError: HttpError;
  allUsersList: ListResults<User> | null;
  allUsersLoading: boolean;
  userUpdateInfoLoading: boolean;
  userUpdateInfoError: HttpError;
  userUpdateInfoSuccess: boolean;
  cartItems: CartItem[];
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
  cartItem: CartItem;
};

export const initialState: UserStateType = {
  user: null,
  userLoading: false,
  userError: null,
  userCreateError: null,
  userCreateLoading: false,
  userCreateSuccess: false,
  userDeleteError: null,
  userDeleteLoading: false,
  userDeleteSuccess: false,
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  usersError: null,
  usersList: null,
  usersLoading: true,
  usersListUpdateNeeded: false,
  currentUser: null,
  currentUserLoading: false,
  allUsersError: null,
  allUsersList: null,
  allUsersLoading: true,
  userUpdateInfoError: null,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
  cartItems: localStorage.getItem('cartItems')
    ? (
        JSON.parse(localStorage.getItem('cartItems') ?? '') as CartItem[]
      ).filter((cartItem) => moment(cartItem.slot.date).isAfter(moment()))
    : [],
};

const fetchCurrentUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const fetchUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userLoading: true,
});

const fetchUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
});

const fetchUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
});

const fetchUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersList: action.usersList,
  usersLoading: false,
  usersError: null,
  usersListUpdateNeeded: false,
});

const fetchUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const fetchAllUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  allUsersLoading: true,
});

const fetchAllUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersList: action.allUsersList,
  allUsersLoading: false,
  allUsersError: null,
});

const fetchAllUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersError: action.allUsersError,
  allUsersLoading: false,
});

const createUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
});

const createUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: null,
  userCreateSuccess: true,
  usersListUpdateNeeded: true,
});

const createUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: action.userCreateError,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: null,
  userUpdateSuccess: true,
  usersListUpdateNeeded: true,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: action.userUpdateError,
});

const deleteUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: true,
});

const deleteUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: null,
  userDeleteSuccess: true,
  usersListUpdateNeeded: true,
});

const deleteUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: action.userDeleteError,
});

const updateUserInfoStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: true,
});

const updateUserInfoSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: null,
  userUpdateInfoSuccess: true,
});

const updateUserInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: action.userUpdateInfoError,
});

const addToCart = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => {
  const cartItems = [...state.cartItems, ...action.cartItems];

  localStorage.setItem('cartItems', JSON.stringify(cartItems));

  return {
    ...state,
    cartItems,
  };
};

const removeFromCart = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => {
  const cartItems = state.cartItems.filter(
    (prevCartItem) =>
      !action.cartItems
        .map((cartItem) => cartItem.uuid)
        .includes(prevCartItem.uuid),
  );

  localStorage.setItem('cartItems', JSON.stringify(cartItems));

  return {
    ...state,
    cartItems,
  };
};

const resetCartItems = (state: UserStateType): UserStateType => {
  localStorage.removeItem('cartItems');
  return { ...state, cartItems: [] };
};

const resetUserListStore = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
  usersList: null,
  usersListUpdateNeeded: false,
  userError: null,
});

const resetUserStore = (state: UserStateType): UserStateType => ({
  ...state,
  user: null,
  userLoading: false,
  userError: null,
});

const resetUserInfoStore = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
  userUpdateInfoError: null,
});

const resetUserCreateStore = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateSuccess: false,
  userCreateError: null,
});

const resetUserUpdateStore = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  userUpdateError: null,
});

const completelyResetUserStore = (): UserStateType => {
  localStorage.removeItem('cartItems');
  return { ...initialState };
};

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.FETCH_ALL_USERS_START:
      return fetchAllUsersStart(state);
    case actionTypes.FETCH_ALL_USERS_SUCCESS:
      return fetchAllUsersSuccess(state, action);
    case actionTypes.FETCH_ALL_USERS_FAIL:
      return fetchAllUsersFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.FETCH_ME_START:
      return fetchCurrentUserStart(state);
    case actionTypes.FETCH_ME_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_ME_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.UPDATE_USER_INFO_START:
      return updateUserInfoStart(state);
    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return updateUserInfoSuccess(state);
    case actionTypes.UPDATE_USER_INFO_FAIL:
      return updateUserInfoFail(state, action);
    case actionTypes.ADD_TO_CART:
      return addToCart(state, action);
    case actionTypes.REMOVE_FROM_CART:
      return removeFromCart(state, action);
    case actionTypes.RESET_CART_ITEMS:
      return resetCartItems(state);
    case actionTypes.RESET_USER_LIST_STORE:
      return resetUserListStore(state);
    case actionTypes.RESET_USER_INFO_STORE:
      return resetUserInfoStore(state);
    case actionTypes.RESET_USER_CREATE_STORE:
      return resetUserCreateStore(state);
    case actionTypes.RESET_USER_UPDATE_STORE:
      return resetUserUpdateStore(state);
    case actionTypes.RESET_USER_STORE:
      return resetUserStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserStore();
    default:
      return state;
  }
};

export default reducer;
