import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, Navigate, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faHouseChimney,
  faUsers,
  faLayerGroup,
  faBagShopping,
  faCube,
  faNewspaper,
  faGear,
  faTablet,
  faGlobe,
  faCartShopping,
  faMoneyCheckDollar,
} from '@fortawesome/free-solid-svg-icons';
import PublicNavigation from '../PublicNavigation/PublicNavigation';
import { routes } from '../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { Language } from '../../domain/Language';
import { Locale } from '../../domain/Translation';
import Footer from '../Footer/Footer';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { Payment, PaymentType } from '../../domain/Payment';
import Modal from '../Modal/Modal';
import PurchaseModal from '../../component/Shop/PurchaseModal/PurchaseModal';
import PaymentResponse from '../../component/Payment/PaymentResponse/PaymentResponse';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { resetPaymentStatus } from '../../store/payment/actions';
import BookTimeReservation from '../../component/BookTime/BookTimeReservation/BookTimeReservation';
import { CartItem } from '../../domain/Cart';
import { resetCartItems } from '../../store/user/actions';
import ShopItemPurchase from '../../component/Shop/ShopItemPurchase/ShopItemPurchase';
import { setIsFirstLogin } from '../../store/auth/actions';
import CookieBar from '../CookieBar/CookieBar';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  selectedLocale: Locale;
  languages: Language[];
  currentUser: User | null;
  jwtToken: string | null;
  payment: Payment | null;
  isLoadingPayment: boolean;
  isSuccessPayment: boolean;
  onResetPaymentStatus: () => void;
  paymentType: PaymentType | null;
  cartItems: CartItem[];
  onResetCartItems: () => void;
  isFirstLogin: boolean;
  onSetIsFirstLogin: (isFirstLogin: boolean) => void;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  selectedLocale,
  languages,
  currentUser,
  jwtToken,
  payment,
  isLoadingPayment,
  isSuccessPayment,
  onResetPaymentStatus,
  paymentType,
  cartItems,
  onResetCartItems,
  isFirstLogin,
  onSetIsFirstLogin,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(true);
  const [isCookiesBarVisible, setIsCookieBarVisible] = useState<boolean>(false);

  const routeLocation = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  const { width } = useWindowSize();

  useEffect(() => {
    if (localStorage.getItem('isCookiesAllowed') === null) {
      setIsCookieBarVisible(true);
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (isFirstLogin) {
      setTimeout(() => {
        navigate(routes.profile.details, {
          replace: true,
          state: { displayWarning: true },
        });
        onSetIsFirstLogin(false);
      });
    }
  }, [isFirstLogin, isAuthenticated]);

  const getNavigationItems = () => {
    return [
      {
        label: translate(intl, 'NAVIGATION.ABOUT_US'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.ABOUT_US_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.NEWS'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.NEWS_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.SHOP'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.SHOP_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.CONTACT'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.CONTACT_LINK').toLowerCase(),
        ),
      },
    ];
  };

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_MANAGEMENT'),
          to: routes.reservations,
          icon: faTablet as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.payment.list,
          icon: faMoneyCheckDollar as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LOCATIONS'),
          to: routes.locations.list,
          icon: faHouseChimney as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUsers as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ORDERS'),
          to: routes.orders.list,
          icon: faCartShopping as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CATEGORIES'),
          to: routes.categories.list,
          icon: faLayerGroup as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ITEMS'),
          to: routes.items.list,
          icon: faBagShopping as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_EUR_PACKAGES'),
          to: routes.eurPackages.list,
          icon: faCube as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_POSTS'),
          to: routes.newsPosts.list,
          icon: faNewspaper as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_SETTINGS'),
          to: routes.settings,
          icon: faGear as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
    window.scrollTo(0, 0);
  }, [routeLocation.key]);

  const getNavigationGroups = () => {
    return SIDEBAR_ITEMS;
  };

  const handleResponseModalClose = () => {
    setIsResponseModalOpen(false);
    onResetPaymentStatus();
    localStorage.removeItem('currentPage');

    if (paymentType === PaymentType.RESERVATION) {
      onResetCartItems();
    }
  };

  const getModalComponent = (
    paymentType: PaymentType,
    payment: Payment,
    isSuccessPayment: boolean,
  ) => {
    if (paymentType === PaymentType.BALANCE_TOP_UP) {
      return <PaymentResponse payment={payment} isSuccess={isSuccessPayment} />;
    }

    if (paymentType === PaymentType.RESERVATION) {
      return (
        <BookTimeReservation
          cartItems={cartItems}
          isDirectPayment={true}
          isSuccess={isSuccessPayment}
        />
      );
    }

    return (
      <ShopItemPurchase isDirectPayment={true} isSuccess={isSuccessPayment} />
    );
  };

  if (!routeLocation.pathname.includes(routes.admin)) {
    return (
      <>
        <PublicNavigation
          onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
          isMobileMenuOpen={isMobileMenuOpen}
          navigationItems={getNavigationItems()}
          isAuthenticated={isAuthenticated}
        />
        <div className={styles.publicContainer}>{children}</div>
        <Footer navigationItems={getNavigationItems()} />
        {isCookiesBarVisible && (
          <CookieBar setIsCookieBarVisible={setIsCookieBarVisible} />
        )}
        {payment && !isLoadingPayment && paymentType && (
          <Modal
            onClose={handleResponseModalClose}
            isOpen={isResponseModalOpen}
            purchaseModal
          >
            <PurchaseModal onClose={handleResponseModalClose}>
              {getModalComponent(paymentType, payment, isSuccessPayment)}
            </PurchaseModal>
          </Modal>
        )}
      </>
    );
  }

  if (routeLocation.pathname.includes(routes.login)) {
    return <>{children}</>;
  }

  const isAdmin =
    currentUser &&
    currentUser.role === Roles.ADMIN &&
    isAuthenticated &&
    jwtToken;

  return (
    <>
      {isAdmin ? (
        <>
          <div className={styles.container}>
            {width && width >= MOBILE_BREAK_POINT && (
              <Sidebar navigationGroups={getNavigationGroups()} />
            )}
            <div
              className={cx(styles.rightSection, {
                [styles.noScroll]: isMobileMenuOpen,
              })}
            >
              <Navigation
                onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
                isMobileMenuOpen={isMobileMenuOpen}
              />
              <div className={styles.content}>{children}</div>
            </div>
          </div>
          {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
            <div className={styles.mobileDrawer}>
              {getNavigationGroups().map((navigationGroup) => (
                <div
                  className={styles.navigationGroup}
                  key={navigationGroup.label}
                >
                  <div className={styles.groupName}>
                    {navigationGroup.label}
                  </div>
                  {navigationGroup.items.map((item) => (
                    <NavLink
                      key={item.label}
                      to={item.to}
                      className={({ isActive }) =>
                        cx(styles.navigationItem, {
                          [styles.activeSubItem]: isActive,
                        })
                      }
                    >
                      {item.label}
                    </NavLink>
                  ))}
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
  isAuthenticated: state.auth.isAuthenticated,
  jwtToken: state.auth.jwtToken,
  isLoadingPayment: state.payment.paymentLoading,
  payment: state.payment.payment,
  isSuccessPayment: state.payment.isSuccessPayment,
  paymentType: state.payment.paymentType,
  cartItems: state.user.cartItems,
  isFirstLogin: state.auth.isFirstLogin,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onResetPaymentStatus: () => dispatch(resetPaymentStatus()),
  onResetCartItems: () => dispatch(resetCartItems()),
  onSetIsFirstLogin: (isFirstLogin: boolean) =>
    dispatch(setIsFirstLogin(isFirstLogin)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
