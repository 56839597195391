import * as actionTypes from './actionTypes';
import { Reservation } from '../../domain/Reservation';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from 'src/common/List/List';

export const createReservationStart = () => ({
  type: actionTypes.CREATE_RESERVATION_START,
});

export const createReservationSuccess = (createdReservation: Reservation) => ({
  type: actionTypes.CREATE_RESERVATION_SUCCESS,
  createdReservation,
});

export const createReservationFail = (reservationCreateError: HttpError) => ({
  type: actionTypes.CREATE_RESERVATION_FAIL,
  reservationCreateError,
});

export const createReservationsStart = () => ({
  type: actionTypes.CREATE_RESERVATIONS_START,
});

export const createReservationsSuccess = (
  createdReservations: Reservation[],
) => ({
  type: actionTypes.CREATE_RESERVATIONS_SUCCESS,
  createdReservations,
});

export const createReservationsFail = (reservationsCreateError: HttpError) => ({
  type: actionTypes.CREATE_RESERVATIONS_FAIL,
  reservationsCreateError,
});

export const deleteReservationStart = () => ({
  type: actionTypes.DELETE_RESERVATION_START,
});

export const deleteReservationSuccess = () => ({
  type: actionTypes.DELETE_RESERVATION_SUCCESS,
});

export const deleteReservationFail = (reservationDeleteError: HttpError) => ({
  type: actionTypes.DELETE_RESERVATION_FAIL,
  reservationDeleteError,
});

export const cancelReservationStart = () => ({
  type: actionTypes.CANCEL_RESERVATION_START,
});

export const cancelReservationSuccess = () => ({
  type: actionTypes.CANCEL_RESERVATION_SUCCESS,
});

export const cancelReservationFail = (reservationCancelError: HttpError) => ({
  type: actionTypes.CANCEL_RESERVATION_FAIL,
  reservationCancelError,
});

export const fetchUserReservationsListStart = () => ({
  type: actionTypes.FETCH_USER_RESERVATIONS_START,
});

export const fetchUserReservationsListSuccess = (
  userReservationsList: ListResults<Reservation>,
) => ({
  type: actionTypes.FETCH_USER_RESERVATIONS_SUCCESS,
  userReservationsList,
});

export const fetchUserReservationsListFailed = (
  userReservationsListFailed: HttpError,
) => ({
  type: actionTypes.FETCH_USER_RESERVATIONS_START,
  userReservationsListFailed,
});

export const resetReservationStore = () => ({
  type: actionTypes.RESET_RESERVATION_STORE,
});
