import React from 'react';
import styles from './Footer.module.scss';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import logoSmall from '../../assets/logo.png';
import ababaLogo from '../../assets/ababa_tech_logo_white.png';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import iconFacebook from '../../assets/icons/facebook.svg';
import iconInstagram from '../../assets/icons/instagram.svg';
import iconPhone from '../../assets/icons/phone.svg';
import iconMail from '../../assets/icons/mail.svg';
import iconLocation from '../../assets/icons/location.svg';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import { Locale } from '../../domain/Translation';
import { NavigationItem } from '../Layout/Layout';
import cx from 'classnames';
import { getLocaleUrl } from '../../utility/url/urlHelper';

type Props = {
  selectedLocale: Locale;
  navigationItems: NavigationItem[];
};

const Footer = ({ selectedLocale, navigationItems }: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.leftContent}>
          <img src={logoSmall} alt="" />
          <div className={styles.description}>
            {translate(intl, 'FOOTER.DESCRIPTION')}
          </div>
          <div className={styles.socials}>
            <div className={styles.label}>
              {translate(intl, 'FOOTER.SOCIAL_FOLLOW_US')}
            </div>
            <ReactSVG className={styles.socialIcon} src={iconFacebook} />
            <ReactSVG className={styles.socialIcon} src={iconInstagram} />
          </div>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.columnInfo}>
            <div className={styles.columnTitle}>
              {translate(intl, 'FOOTER.PAGES')}
            </div>
            <div className={styles.columnItems}>
              {navigationItems.map((navigationItem) => (
                <Link
                  className={styles.columnItem}
                  key={navigationItem.to}
                  to={navigationItem.to}
                >
                  {navigationItem.label}
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.columnInfo}>
            <div className={styles.columnTitle}>
              {translate(intl, 'FOOTER.PAGE_RULES')}
            </div>
            <div className={styles.columnItems}>
              <Link
                className={styles.columnItem}
                to={getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
                  ':slug',
                  translate(
                    intl,
                    'NAVIGATION.PRIVACY_POLICY_LINK',
                  ).toLowerCase(),
                )}
              >
                {translate(intl, 'FOOTER.PRIVACY_POLICY')}
              </Link>
              <Link
                className={styles.columnItem}
                to={getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
                  ':slug',
                  translate(
                    intl,
                    'NAVIGATION.TERMS_AND_CONDITIONS_LINK',
                  ).toLowerCase(),
                )}
              >
                {translate(intl, 'FOOTER.TERMS_AND_CONDITIONS')}
              </Link>
            </div>
          </div>
          <div className={styles.columnInfo}>
            <div className={styles.columnTitle}>
              {translate(intl, 'FOOTER.CONTACTS')}
            </div>
            <div className={styles.columnItems}>
              <a
                className={cx(styles.columnItem, styles.fullOpacity)}
                href={`tel:${translate(intl, 'FOOTER.PHONE_NUMBER')}`}
              >
                <ReactSVG className={styles.icon} src={iconPhone} />{' '}
                {translate(intl, 'FOOTER.PHONE_NUMBER')}
              </a>
              <a
                className={cx(styles.columnItem, styles.fullOpacity)}
                href={`mailto:${translate(intl, 'FOOTER.EMAIL')}`}
              >
                <ReactSVG className={styles.icon} src={iconMail} />{' '}
                {translate(intl, 'FOOTER.EMAIL')}
              </a>
              <span className={cx(styles.columnItem, styles.fullOpacity)}>
                <ReactSVG className={styles.icon} src={iconLocation} />{' '}
                {translate(intl, 'FOOTER.LOCATION')}
              </span>
            </div>
          </div>
          <div className={styles.columnInfo}>
            <div className={styles.columnTitle}>
              {translate(intl, 'FOOTER.WORKING_HOURS')}
            </div>
            <div className={styles.columnItems}>
              <span className={styles.columnItem}>
                <span className={styles.workingHoursDay}>
                  {translate(intl, 'FOOTER.WORKING_HOURS_WORKDAY_LABEL')}
                </span>{' '}
                <span className={styles.workingHours}>
                  {translate(intl, 'FOOTER.WORKING_HOURS_WORKDAY')}
                </span>
              </span>
              <span className={styles.columnItem}>
                <span className={styles.workingHoursDay}>
                  {translate(intl, 'FOOTER.WORKING_HOURS_WEEKEND_LABEL')}
                </span>{' '}
                <span className={styles.workingHours}>
                  {translate(intl, 'FOOTER.WORKING_HOURS_WEEKEND')}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomLine}>
        <span>{translate(intl, 'FOOTER.COPYRIGHT')}</span>
        <span>|</span>
        <div className={styles.ababaContainer}>
          <a
            href="https://ababa.tech"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.ababaLink}
          >
            <img src={ababaLogo} alt="ababa.tech" />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(Footer);
