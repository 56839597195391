export const routes = {
  homepage: '/',
  login: '/login',
  register: '/register',
  registrationConfirmation: '/registration-confirmation/:code',
  remind: '/remind-password',
  resetPassword: '/password-reset/:code',
  profile: {
    details: '/profile',
    balance: {
      paymentResponse: 'payment/:paymentNumber/response',
    },
  },
  bookTime: '/book-time',
  dynamicPage: '/:locale/:slug',
  dynamicPageWithContent: '/:locale/:slug/:contentSlug',
  admin: '/admin',
  locations: {
    list: '/admin/locations',
    create: '/admin/locations/new',
    edit: '/admin/locations/:id',
  },
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  categories: {
    list: '/admin/categories',
    create: '/admin/categories/new',
    edit: '/admin/categories/:id',
  },
  items: {
    list: '/admin/items',
    create: '/admin/items/new',
    edit: '/admin/items/:id',
  },
  eurPackages: {
    list: '/admin/eur-packages',
    create: '/admin/eur-packages/new',
    edit: '/admin/eur-packages/:id',
  },
  newsPosts: {
    list: '/admin/news-posts',
    create: '/admin/news-posts/new',
    edit: '/admin/news-posts/:id',
  },
  orders: {
    list: '/admin/orders',
  },
  payment: {
    list: '/admin/payments',
  },
  settings: '/admin/settings',
  reservations: '/admin/reservations',
  translations: '/admin/translations',
};
