export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const FETCH_USER_ORDERS_START = 'FETCH_USER_ORDERS_START';
export const FETCH_USER_ORDERS_SUCCESS = 'FETCH_USER_ORDERS_SUCCESS';
export const FETCH_USER_ORDERS_FAIL = 'FETCH_USER_ORDERS_FAIL';

export const RESET_ORDER_STORE = 'RESET_ORDER_STORE';

export type OrderActionTypes =
  | typeof CREATE_ORDER_START
  | typeof CREATE_ORDER_SUCCESS
  | typeof CREATE_ORDER_FAIL
  | typeof RESET_ORDER_STORE
  | typeof FETCH_ORDERS_START
  | typeof FETCH_ORDERS_SUCCESS
  | typeof FETCH_ORDERS_FAIL
  | typeof FETCH_USER_ORDERS_START
  | typeof FETCH_USER_ORDERS_SUCCESS
  | typeof FETCH_USER_ORDERS_FAIL;
