import * as actionTypes from './actionTypes';
import { SlotActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Slot } from '../../domain/Slot';

export type SlotStateType = {
  slots: Slot[];
  slotsLoading: boolean;
  slotsError: HttpError;
  publicSlots: Slot[];
  publicSlotsLoading: boolean;
  publicSlotsError: HttpError;
  slotCreateLoading: boolean;
  slotCreateError: HttpError;
  slotCreateSuccess: boolean;
  createdSlot: Slot | null;
  slotUpdateLoading: boolean;
  slotUpdateError: HttpError;
  slotUpdateSuccess: boolean;
  slotDeleteLoading: boolean;
  slotDeleteError: HttpError;
  slotDeleteSuccess: boolean;
  dayDeleteLoading: boolean;
  dayDeleteError: HttpError;
  dayDeleteSuccess: boolean;
  selectedTime: string | null;
};

export type SlotActionType = SlotStateType & {
  type: SlotActionTypes;
  value: string;
};

export const initialState: SlotStateType = {
  slots: [],
  slotsLoading: false,
  slotsError: null,
  publicSlots: [],
  publicSlotsLoading: false,
  publicSlotsError: null,
  slotCreateLoading: false,
  slotCreateError: null,
  slotCreateSuccess: false,
  createdSlot: null,
  slotUpdateLoading: false,
  slotUpdateError: null,
  slotUpdateSuccess: false,
  slotDeleteLoading: false,
  slotDeleteError: null,
  slotDeleteSuccess: false,
  dayDeleteSuccess: false,
  dayDeleteError: null,
  dayDeleteLoading: false,
  selectedTime: null,
};

const fetchSlotsStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotsLoading: true,
});

const fetchSlotsSuccess = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slots: action.slots,
  slotsLoading: false,
  slotsError: null,
  slotDeleteSuccess: false,
  slotUpdateSuccess: false,
  slotCreateSuccess: false,
  dayDeleteSuccess: false,
  slotCreateError: null,
  slotUpdateError: null,
  slotDeleteError: null,
  dayDeleteError: null,
});

const fetchSlotsFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotsError: action.slotsError,
  slotsLoading: false,
});

const fetchPublicSlotsStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  publicSlotsLoading: true,
});

const fetchPublicSlotsSuccess = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  publicSlots: action.publicSlots,
  publicSlotsLoading: false,
  publicSlotsError: null,
});

const fetchPublicSlotsFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  publicSlotsError: action.publicSlotsError,
  publicSlotsLoading: false,
});

const createSlotStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotCreateLoading: true,
});

const createSlotSuccess = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotCreateLoading: false,
  slotCreateError: null,
  slotCreateSuccess: true,
  createdSlot: action.createdSlot,
});

const createSlotFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotCreateLoading: false,
  slotCreateError: action.slotCreateError,
});

const updateSlotStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotUpdateLoading: true,
});

const updateSlotSuccess = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotUpdateLoading: false,
  slotUpdateError: null,
  slotUpdateSuccess: true,
});

const updateSlotFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotUpdateLoading: false,
  slotUpdateError: action.slotUpdateError,
});

const deleteSlotStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotDeleteLoading: true,
});

const deleteSlotSuccess = (state: SlotStateType): SlotStateType => ({
  ...state,
  slotDeleteLoading: false,
  slotDeleteError: null,
  slotDeleteSuccess: true,
});

const deleteSlotFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  slotDeleteLoading: false,
  slotDeleteError: action.slotDeleteError,
});

const deleteDayStart = (state: SlotStateType): SlotStateType => ({
  ...state,
  dayDeleteLoading: true,
});

const deleteDaySuccess = (state: SlotStateType): SlotStateType => ({
  ...state,
  dayDeleteLoading: false,
  dayDeleteError: null,
  dayDeleteSuccess: true,
});

const deleteDayFail = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  dayDeleteLoading: false,
  dayDeleteError: action.dayDeleteError,
});

const setTimeSelect = (
  state: SlotStateType,
  action: SlotActionType,
): SlotStateType => ({
  ...state,
  selectedTime: action.value,
});

const resetTimeSelectStore = (state: SlotStateType): SlotStateType => ({
  ...state,
  selectedTime: null,
});

const resetSlots = (state: SlotStateType): SlotStateType => ({
  ...state,
  slots: [],
});

const completelyResetSlotStore = (): SlotStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: SlotActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SLOTS_START:
      return fetchSlotsStart(state);
    case actionTypes.FETCH_SLOTS_SUCCESS:
      return fetchSlotsSuccess(state, action);
    case actionTypes.FETCH_SLOTS_FAIL:
      return fetchSlotsFail(state, action);
    case actionTypes.FETCH_PUBLIC_SLOTS_START:
      return fetchPublicSlotsStart(state);
    case actionTypes.FETCH_PUBLIC_SLOTS_SUCCESS:
      return fetchPublicSlotsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SLOTS_FAIL:
      return fetchPublicSlotsFail(state, action);
    case actionTypes.CREATE_SLOT_START:
      return createSlotStart(state);
    case actionTypes.CREATE_SLOT_SUCCESS:
      return createSlotSuccess(state, action);
    case actionTypes.CREATE_SLOT_FAIL:
      return createSlotFail(state, action);
    case actionTypes.UPDATE_SLOT_START:
      return updateSlotStart(state);
    case actionTypes.UPDATE_SLOT_SUCCESS:
      return updateSlotSuccess(state);
    case actionTypes.UPDATE_SLOT_FAIL:
      return updateSlotFail(state, action);
    case actionTypes.DELETE_SLOT_START:
      return deleteSlotStart(state);
    case actionTypes.DELETE_SLOT_SUCCESS:
      return deleteSlotSuccess(state);
    case actionTypes.DELETE_SLOT_FAIL:
      return deleteSlotFail(state, action);
    case actionTypes.DELETE_DAY_START:
      return deleteDayStart(state);
    case actionTypes.DELETE_DAY_SUCCESS:
      return deleteDaySuccess(state);
    case actionTypes.DELETE_DAY_FAIL:
      return deleteDayFail(state, action);
    case actionTypes.SELECT_TIME:
      return setTimeSelect(state, action);
    case actionTypes.RESET_SELECT_TIME_STORE:
      return resetTimeSelectStore(state);
    case actionTypes.RESET_SLOTS:
      return resetSlots(state);
    case actionTypes.LOGOUT:
      return completelyResetSlotStore();
    default:
      return state;
  }
};

export default reducer;
