import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createEurPackageFail,
  createEurPackageStart,
  createEurPackageSuccess,
  deleteEurPackageFail,
  deleteEurPackageStart,
  deleteEurPackageSuccess,
  fetchEurPackagesFail,
  fetchEurPackagesStart,
  fetchEurPackagesSuccess,
  fetchEurPackageFail,
  fetchEurPackageStart,
  fetchEurPackageSuccess,
  updateEurPackageFail,
  updateEurPackageStart,
  updateEurPackageSuccess,
  fetchPublicEurPackagesStart,
  fetchPublicEurPackagesSuccess,
  fetchPublicEurPackagesFail,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { EntityTranslation } from '../../domain/EntityTranslation';

const API_URL = '/eur-packages';

export type EurPackageCreateRequest = {
  price: number;
  eur: number;
  description: string;
  name: string;
  translations: EntityTranslation[];
};

export type EurPackageUpdateRequest = {
  id: number;
  price: number;
  eur: number;
  description: string;
  name: string;
  translations: EntityTranslation[];
};

export const fetchEurPackages =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchEurPackagesStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchEurPackagesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEurPackagesFail(err.response.data.error));
      });
  };

export const fetchEurPackage = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchEurPackageStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchEurPackageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchEurPackageFail(err.response.data.error));
    });
};

export const fetchPublicEurPackages =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchPublicEurPackagesStart());
    return axios
      .get(`${API_URL}/public`, { params })
      .then((response) => {
        dispatch(fetchPublicEurPackagesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicEurPackagesFail(err.response.data.error));
      });
  };

export const createEurPackage =
  (inputs: EurPackageCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createEurPackageStart());
    return axios
      .post(API_URL, { ...inputs })
      .then((response) => {
        dispatch(createEurPackageSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.EUR_PACKAGE_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createEurPackageFail(err.response.data.message));
      });
  };

export const updateEurPackage =
  (inputs: EurPackageUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateEurPackageStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, { ...inputs })
      .then((response) => {
        dispatch(updateEurPackageSuccess());
        showToast(translate(intl, 'SUCCESS.EUR_PACKAGE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateEurPackageFail(err.response.data.message));
      });
  };

export const deleteEurPackage =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteEurPackageStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        dispatch(deleteEurPackageSuccess());
        showToast(translate(intl, 'SUCCESS.EUR_PACKAGE_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteEurPackageFail(err.response.data.message));
      });
  };
