import * as actionTypes from './actionTypes';
import { EurPackageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EurPackage } from '../../domain/EurPackage';
import { ListResults } from '../../common/List/List';

export type EurPackageStateType = {
  eurPackagesList: ListResults<EurPackage> | null;
  eurPackagesLoading: boolean;
  eurPackagesError: HttpError;
  publicEurPackagesList: ListResults<EurPackage> | null;
  publicEurPackagesLoading: boolean;
  publicEurPackagesError: HttpError;
  eurPackagesListUpdateNeeded: boolean;
  eurPackageCreateLoading: boolean;
  eurPackageCreateError: HttpError;
  eurPackageCreateSuccess: boolean;
  createdEurPackage: EurPackage | null;
  eurPackageUpdateLoading: boolean;
  eurPackageUpdateError: HttpError;
  eurPackageUpdateSuccess: boolean;
  eurPackageDeleteLoading: boolean;
  eurPackageDeleteError: HttpError;
  eurPackageDeleteSuccess: boolean;
  eurPackage: EurPackage | null;
  eurPackageLoading: boolean;
  eurPackageError: HttpError;
};

export type EurPackageActionType = EurPackageStateType & {
  type: EurPackageActionTypes;
};

export const initialState: EurPackageStateType = {
  eurPackagesList: null,
  eurPackagesLoading: true,
  eurPackagesError: null,
  publicEurPackagesList: null,
  publicEurPackagesLoading: false,
  publicEurPackagesError: null,
  eurPackagesListUpdateNeeded: false,
  eurPackageCreateLoading: false,
  eurPackageCreateError: null,
  eurPackageCreateSuccess: false,
  createdEurPackage: null,
  eurPackageUpdateLoading: false,
  eurPackageUpdateError: null,
  eurPackageUpdateSuccess: false,
  eurPackageDeleteLoading: false,
  eurPackageDeleteError: null,
  eurPackageDeleteSuccess: false,
  eurPackage: null,
  eurPackageLoading: false,
  eurPackageError: null,
};

const fetchEurPackagesStart = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  eurPackagesLoading: true,
});

const fetchEurPackagesSuccess = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackagesList: action.eurPackagesList,
  eurPackagesLoading: false,
  eurPackagesError: null,
  eurPackagesListUpdateNeeded: false,
});

const fetchEurPackagesFail = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackagesError: action.eurPackagesError,
  eurPackagesLoading: false,
});

const fetchPublicEurPackagesStart = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  publicEurPackagesLoading: true,
});

const fetchPublicEurPackagesSuccess = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  publicEurPackagesList: action.publicEurPackagesList,
  publicEurPackagesLoading: false,
  publicEurPackagesError: null,
});

const fetchPublicEurPackagesFail = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  publicEurPackagesError: action.publicEurPackagesError,
  publicEurPackagesLoading: false,
});

const fetchEurPackageStart = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  eurPackageLoading: true,
  eurPackageCreateSuccess: false,
  eurPackageCreateError: null,
});

const fetchEurPackageSuccess = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackage: action.eurPackage,
  eurPackageLoading: false,
  eurPackageError: null,
});

const fetchEurPackageFail = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackageError: action.eurPackageError,
  eurPackageLoading: false,
});

const createEurPackageStart = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  eurPackageCreateLoading: true,
});

const createEurPackageSuccess = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackageCreateLoading: false,
  eurPackageCreateError: null,
  eurPackageCreateSuccess: true,
  createdEurPackage: action.createdEurPackage,
});

const createEurPackageFail = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackageCreateLoading: false,
  eurPackageCreateError: action.eurPackageCreateError,
});

const updateEurPackageStart = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  eurPackageUpdateLoading: true,
  eurPackageUpdateSuccess: false,
});

const updateEurPackageSuccess = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  eurPackageUpdateLoading: false,
  eurPackageUpdateError: null,
  eurPackageUpdateSuccess: true,
});

const updateEurPackageFail = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackageUpdateLoading: false,
  eurPackageUpdateError: action.eurPackageUpdateError,
});

const deleteEurPackageStart = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  eurPackageDeleteLoading: true,
});

const deleteEurPackageSuccess = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  eurPackageDeleteLoading: false,
  eurPackageDeleteError: null,
  eurPackageDeleteSuccess: true,
  eurPackagesListUpdateNeeded: true,
});

const deleteEurPackageFail = (
  state: EurPackageStateType,
  action: EurPackageActionType,
): EurPackageStateType => ({
  ...state,
  eurPackageDeleteLoading: false,
  eurPackageDeleteError: action.eurPackageDeleteError,
});

const completelyResetEurPackageStore = (): EurPackageStateType => ({
  ...initialState,
});

const resetPublicEurPackagesList = (
  state: EurPackageStateType,
): EurPackageStateType => ({
  ...state,
  publicEurPackagesList: null,
});

const reducer = (state = initialState, action: EurPackageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EUR_PACKAGES_START:
      return fetchEurPackagesStart(state);
    case actionTypes.FETCH_EUR_PACKAGES_SUCCESS:
      return fetchEurPackagesSuccess(state, action);
    case actionTypes.FETCH_EUR_PACKAGES_FAIL:
      return fetchEurPackagesFail(state, action);
    case actionTypes.FETCH_PUBLIC_EUR_PACKAGES_START:
      return fetchPublicEurPackagesStart(state);
    case actionTypes.FETCH_PUBLIC_EUR_PACKAGES_SUCCESS:
      return fetchPublicEurPackagesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_EUR_PACKAGES_FAIL:
      return fetchPublicEurPackagesFail(state, action);
    case actionTypes.FETCH_EUR_PACKAGE_START:
      return fetchEurPackageStart(state);
    case actionTypes.FETCH_EUR_PACKAGE_SUCCESS:
      return fetchEurPackageSuccess(state, action);
    case actionTypes.FETCH_EUR_PACKAGE_FAIL:
      return fetchEurPackageFail(state, action);
    case actionTypes.CREATE_EUR_PACKAGE_START:
      return createEurPackageStart(state);
    case actionTypes.CREATE_EUR_PACKAGE_SUCCESS:
      return createEurPackageSuccess(state, action);
    case actionTypes.CREATE_EUR_PACKAGE_FAIL:
      return createEurPackageFail(state, action);
    case actionTypes.UPDATE_EUR_PACKAGE_START:
      return updateEurPackageStart(state);
    case actionTypes.UPDATE_EUR_PACKAGE_SUCCESS:
      return updateEurPackageSuccess(state);
    case actionTypes.UPDATE_EUR_PACKAGE_FAIL:
      return updateEurPackageFail(state, action);
    case actionTypes.DELETE_EUR_PACKAGE_START:
      return deleteEurPackageStart(state);
    case actionTypes.DELETE_EUR_PACKAGE_SUCCESS:
      return deleteEurPackageSuccess(state);
    case actionTypes.DELETE_EUR_PACKAGE_FAIL:
      return deleteEurPackageFail(state, action);
    case actionTypes.RESET_PUBLIC_EUR_PACKAGES_LIST:
      return resetPublicEurPackagesList(state);
    case actionTypes.RESET_EUR_PACKAGE_STORE:
    case actionTypes.LOGOUT:
      return completelyResetEurPackageStore();
    default:
      return state;
  }
};

export default reducer;
