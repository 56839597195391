import React from 'react';
import { EurPackage } from '../../../domain/EurPackage';
import styles from './EurPackageBlock.module.scss';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import Button from '../../../common/Button/Button';
import { ReactSVG } from 'react-svg';
import cartIcon from '../../../assets/icons/cart.svg';
import { connect } from 'react-redux';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import Alert from '../../../common/Alert/Alert';
import { User } from '../../../domain/User';
import { getTranslationByKey } from '../../../utility/domainTranslation/translator';
import { Language } from '../../../domain/Language';
import { Locale } from '../../../domain/Translation';

type Props = {
  eurPackage: EurPackage;
  handleSubmit: (eurPackage: EurPackage) => void;
  isButtonDisabled: (user: User | null, eurPackage: EurPackage) => boolean;
  isLoading?: boolean;
  user: User | null;
  selectedLocale: Locale;
  languages: Language[];
};

export const EurPackageBlock = ({
  eurPackage,
  isLoading,
  user,
  handleSubmit,
  isButtonDisabled,
  selectedLocale,
  languages,
}: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.eurPackageContainer}>
      {isButtonDisabled(user, eurPackage) && (
        <Alert variant="info" capitalize={false}>
          {translate(intl, 'VALIDATION.EUR_PACKAGE_FIRST_PURCHASE')}
        </Alert>
      )}
      <div className={styles.price}>{eurPackage.price} &euro;</div>
      {/* <div className={styles.eur}>
        {`${eurPackage.eur} ${translate(intl, 'EUR_PACKAGE_BLOCK.EUR')}`}
      </div> TODO uncomment when eurPackage value is needed */}
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html:
            getTranslationByKey(
              'eurPackage',
              eurPackage.id,
              selectedLocale,
              'description',
              languages,
            ) ?? eurPackage.description,
        }}
      ></div>
      <div className={styles.orderButton}>
        <Button
          buttonVariant="contained"
          color="secondary"
          onClick={() => handleSubmit(eurPackage)}
          isLoading={isLoading}
          isDisabled={isButtonDisabled(user, eurPackage)}
        >
          <span>{translate(intl, 'EUR_PACKAGE_BLOCK.BUY_EUR')}</span>
          <ReactSVG className={styles.cartIcon} src={cartIcon} />
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  isLoading: state.payment.topUpCreateLoading,
});

export default connect(mapStateToProps)(EurPackageBlock);
