export const FETCH_WORKING_HOURS_START = 'FETCH_WORKING_HOURS_START';
export const FETCH_WORKING_HOURS_SUCCESS = 'FETCH_WORKING_HOURS_SUCCESS';
export const FETCH_WORKING_HOURS_FAIL = 'FETCH_WORKING_HOURS_FAIL';

export const UPDATE_WORKING_HOUR_START = 'UPDATE_WORKING_HOUR_START';
export const UPDATE_WORKING_HOUR_SUCCESS = 'UPDATE_WORKING_HOUR_SUCCESS';
export const UPDATE_WORKING_HOUR_FAIL = 'UPDATE_WORKING_HOUR_FAIL';

export const CREATE_WORKING_HOUR_START = 'CREATE_WORKING_HOUR_START';
export const CREATE_WORKING_HOUR_SUCCESS = 'CREATE_WORKING_HOUR_SUCCESS';
export const CREATE_WORKING_HOUR_FAIL = 'CREATE_WORKING_HOUR_FAIL';

export const DELETE_WORKING_HOUR_START = 'DELETE_WORKING_HOUR_START';
export const DELETE_WORKING_HOUR_SUCCESS = 'DELETE_WORKING_HOUR_SUCCESS';
export const DELETE_WORKING_HOUR_FAIL = 'DELETE_WORKING_HOUR_FAIL';

export const RESET_WORKING_HOUR_STORE = 'RESET_WORKING_HOUR_STORE';

export const RESET_WORKING_SUCCESS_STATE = 'RESET_WORKING_SUCCESS_STATE';

export const LOGOUT = 'LOGOUT';

export type WorkingHourActionTypes =
  | typeof FETCH_WORKING_HOURS_START
  | typeof FETCH_WORKING_HOURS_SUCCESS
  | typeof FETCH_WORKING_HOURS_FAIL
  | typeof CREATE_WORKING_HOUR_START
  | typeof CREATE_WORKING_HOUR_SUCCESS
  | typeof CREATE_WORKING_HOUR_FAIL
  | typeof UPDATE_WORKING_HOUR_START
  | typeof UPDATE_WORKING_HOUR_SUCCESS
  | typeof UPDATE_WORKING_HOUR_FAIL
  | typeof DELETE_WORKING_HOUR_START
  | typeof DELETE_WORKING_HOUR_SUCCESS
  | typeof DELETE_WORKING_HOUR_FAIL
  | typeof RESET_WORKING_HOUR_STORE
  | typeof RESET_WORKING_SUCCESS_STATE
  | typeof LOGOUT;
