import axios from '../../config/Axios/axios-instance';
import {
  loginGoogleStart,
  loginGoogleSuccess,
  loginGoogleFail,
  logout,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFail,
  loginStart,
  loginSuccess,
  loginFail,
  registrationStart,
  registrationSuccess,
  registrationFail,
  registerConfirmStart,
  registerConfirmSuccess,
  registerConfirmFail,
  remindStart,
  remindSuccess,
  remindFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  validateCodeStart,
  validateCodeSuccess,
  validateCodeFail,
  loginFacebookStart,
  loginFacebookSuccess,
  loginFacebookFail,
  setIsFirstLogin,
} from './actions';
import { Dispatch } from 'redux';

const API_URL = '/auth';

export type LoginGoogleRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  token: string;
};

export type LoginFacebookRequest = {
  token: string;
};

export type LoginRequest = {
  email: string;
  password: string;
};

export type RegistrationRequest = {
  email: string;
  password: string;
  name: string;
  surname: string;
};

export type RemindRequest = {
  email: string;
};

export type ResetPasswordRequest = {
  email: string;
  password: string;
  code: string;
};

export type ValidateCodeRequest = {
  code: string;
};

export type ConfirmRegistrationRequest = {
  code: string;
};

export const loginWithGoogle =
  (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
    dispatch(loginGoogleStart());
    return axios
      .post(`${API_URL}/google`, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(setIsFirstLogin(response.data.isFirstTime));
        dispatch(loginGoogleSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(loginGoogleFail(err.response.data.message));
      });
  };

export const loginWithFacebook =
  (inputs: LoginFacebookRequest) => (dispatch: Dispatch) => {
    dispatch(loginFacebookStart());
    return axios
      .post(`${API_URL}/facebook`, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(setIsFirstLogin(response.data.isFirstTime));
        dispatch(loginFacebookSuccess());
      })
      .catch((err) => {
        dispatch(loginFacebookFail(err.response.data.message));
      });
  };

export const login = (inputs: LoginRequest) => (dispatch: Dispatch) => {
  dispatch(loginStart());
  return axios
    .post(`${API_URL}/login`, {
      ...inputs,
    })
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(setIsFirstLogin(response.data.isFirstTime));
      dispatch(loginSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(loginFail(err.response.data.message));
    });
};

export const register =
  (inputs: RegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registrationStart());
    return axios
      .post(`${API_URL}/register`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(registrationSuccess());
      })
      .catch((err) => {
        dispatch(registrationFail(err.response.data.message));
      });
  };

export const refreshToken = () => (dispatch: Dispatch) => {
  dispatch(refreshTokenStart());
  return axios
    .post(`${API_URL}/refresh`)
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(refreshTokenSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(refreshTokenFail(err.response.data.message));
    });
};

export const confirmRegistration =
  (inputs: ConfirmRegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registerConfirmStart());
    return axios
      .post(`${API_URL}/confirm-registration`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(setIsFirstLogin(response.data.isFirstTime));
        dispatch(registerConfirmSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(registerConfirmFail(err.response.data.message));
      });
  };

export const remind = (inputs: RemindRequest) => (dispatch: Dispatch) => {
  dispatch(remindStart());
  return axios
    .post(`${API_URL}/remind`, {
      ...inputs,
    })
    .then(async (response) => {
      dispatch(remindSuccess());
    })
    .catch((err) => {
      dispatch(remindFail(err.response.data.message));
    });
};

export const resetPassword =
  (inputs: ResetPasswordRequest) => (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    return axios
      .patch(`${API_URL}/reset-password`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(setIsFirstLogin(response.data.isFirstTime));
        dispatch(resetPasswordSuccess());
      })
      .catch((err) => {
        dispatch(resetPasswordFail(err.response.data.message));
      });
  };

export const validateCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateCodeStart());
    return axios
      .post(`${API_URL}/validate-confirmation-code`, {
        ...inputs,
      })
      .then(async (response) => {
        dispatch(validateCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateCodeFail(err.response.data.message));
      });
  };

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(refreshTokenSuccess(token)) : dispatch(logout());
};

export const logoutUser = () => (dispatch: Dispatch) => {
  dispatch(logout());
};
