import * as actionTypes from './actionTypes';
import { ReservationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Reservation } from '../../domain/Reservation';
import { ListResults } from 'src/common/List/List';

export type ReservationStateType = {
  reservationCreateLoading: boolean;
  reservationCreateError: HttpError;
  reservationCreateSuccess: boolean;
  createdReservation: Reservation | null;
  reservationsCreateLoading: boolean;
  reservationsCreateError: HttpError;
  reservationsCreateSuccess: boolean;
  createdReservations: Reservation[] | null;
  reservationDeleteLoading: boolean;
  reservationDeleteError: HttpError;
  reservationDeleteSuccess: boolean;
  reservationCancelLoading: boolean;
  reservationCancelError: HttpError;
  reservationCancelSuccess: boolean;
  userReservationsList: ListResults<Reservation> | null;
  userReservationsListLoading: boolean;
  userReservationsListSuccess: boolean;
  userReservationsListError: HttpError;
};

export type ReservationActionType = ReservationStateType & {
  type: ReservationActionTypes;
};

export const initialState: ReservationStateType = {
  reservationCreateLoading: false,
  reservationCreateError: null,
  reservationCreateSuccess: false,
  createdReservation: null,
  reservationsCreateLoading: false,
  reservationsCreateError: null,
  reservationsCreateSuccess: false,
  createdReservations: null,
  reservationDeleteLoading: false,
  reservationDeleteError: null,
  reservationDeleteSuccess: false,
  reservationCancelLoading: false,
  reservationCancelError: null,
  reservationCancelSuccess: false,
  userReservationsList: null,
  userReservationsListLoading: false,
  userReservationsListSuccess: false,
  userReservationsListError: null,
};

const createReservationStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationCreateLoading: true,
});

const createReservationSuccess = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationCreateLoading: false,
  reservationCreateError: null,
  reservationCreateSuccess: true,
  createdReservation: action.createdReservation,
});

const createReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationCreateLoading: false,
  reservationCreateError: action.reservationCreateError,
});

const createReservationsStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationsCreateLoading: true,
});

const createReservationsSuccess = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationsCreateLoading: false,
  reservationsCreateError: null,
  reservationsCreateSuccess: true,
  createdReservations: action.createdReservations,
});

const createReservationsFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationsCreateLoading: false,
  reservationsCreateError: action.reservationsCreateError,
});

const deleteReservationStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: true,
});

const deleteReservationSuccess = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: false,
  reservationDeleteError: null,
  reservationDeleteSuccess: true,
});

const deleteReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: false,
  reservationDeleteError: action.reservationDeleteError,
});

const cancelReservationStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationCancelLoading: true,
});

const cancelReservationSuccess = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationCancelLoading: false,
  reservationCancelError: null,
  reservationCancelSuccess: true,
});

const cancelReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationCancelLoading: false,
  reservationCancelError: action.reservationCancelError,
});

const fetchUserReservationsListStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  userReservationsListLoading: true,
});

const fetchUserReservationsListSuccess = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsListLoading: false,
  userReservationsList: action.userReservationsList,
});

const fetchUserReservationsListFailed = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsListLoading: false,
  userReservationsListError: action.userReservationsListError,
});

const resetReservationStore = (): ReservationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ReservationActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_RESERVATION_START:
      return createReservationStart(state);
    case actionTypes.CREATE_RESERVATION_SUCCESS:
      return createReservationSuccess(state, action);
    case actionTypes.CREATE_RESERVATION_FAIL:
      return createReservationFail(state, action);
    case actionTypes.CREATE_RESERVATIONS_START:
      return createReservationsStart(state);
    case actionTypes.CREATE_RESERVATIONS_SUCCESS:
      return createReservationsSuccess(state, action);
    case actionTypes.CREATE_RESERVATIONS_FAIL:
      return createReservationsFail(state, action);
    case actionTypes.DELETE_RESERVATION_START:
      return deleteReservationStart(state);
    case actionTypes.DELETE_RESERVATION_SUCCESS:
      return deleteReservationSuccess(state);
    case actionTypes.DELETE_RESERVATION_FAIL:
      return deleteReservationFail(state, action);
    case actionTypes.CANCEL_RESERVATION_START:
      return cancelReservationStart(state);
    case actionTypes.CANCEL_RESERVATION_SUCCESS:
      return cancelReservationSuccess(state);
    case actionTypes.CANCEL_RESERVATION_FAIL:
      return cancelReservationFail(state, action);
    case actionTypes.FETCH_USER_RESERVATIONS_START:
      return fetchUserReservationsListStart(state);
    case actionTypes.FETCH_USER_RESERVATIONS_SUCCESS:
      return fetchUserReservationsListSuccess(state, action);
    case actionTypes.FETCH_USER_RESERVATIONS_FAIL:
      return fetchUserReservationsListFailed(state, action);
    case actionTypes.RESET_RESERVATION_STORE:
      return resetReservationStore();
    default:
      return state;
  }
};

export default reducer;
