import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EurPackage } from '../../domain/EurPackage';
import { ListResults } from '../../common/List/List';

export const fetchEurPackagesStart = () => ({
  type: actionTypes.FETCH_EUR_PACKAGES_START,
});

export const fetchEurPackagesSuccess = (
  eurPackagesList: ListResults<EurPackage>,
) => ({
  type: actionTypes.FETCH_EUR_PACKAGES_SUCCESS,
  eurPackagesList,
});

export const fetchEurPackagesFail = (eurPackagesError: HttpError) => ({
  type: actionTypes.FETCH_EUR_PACKAGES_FAIL,
  eurPackagesError,
});

export const fetchPublicEurPackagesStart = () => ({
  type: actionTypes.FETCH_PUBLIC_EUR_PACKAGES_START,
});

export const fetchPublicEurPackagesSuccess = (
  publicEurPackagesList: ListResults<EurPackage>,
) => ({
  type: actionTypes.FETCH_PUBLIC_EUR_PACKAGES_SUCCESS,
  publicEurPackagesList,
});

export const fetchPublicEurPackagesFail = (
  publicEurPackagesError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_EUR_PACKAGES_FAIL,
  publicEurPackagesError,
});

export const fetchEurPackageStart = () => ({
  type: actionTypes.FETCH_EUR_PACKAGE_START,
});

export const fetchEurPackageSuccess = (eurPackage: EurPackage) => ({
  type: actionTypes.FETCH_EUR_PACKAGE_SUCCESS,
  eurPackage,
});

export const fetchEurPackageFail = (eurPackageError: HttpError) => ({
  type: actionTypes.FETCH_EUR_PACKAGE_FAIL,
  eurPackageError,
});

export const fetchEurPackageOptionsFail = (
  eurPackageOptionsError: HttpError,
) => ({
  type: actionTypes.FETCH_EUR_PACKAGE_FAIL,
  eurPackageOptionsError,
});

export const createEurPackageStart = () => ({
  type: actionTypes.CREATE_EUR_PACKAGE_START,
});

export const createEurPackageSuccess = (createdEurPackage: EurPackage) => ({
  type: actionTypes.CREATE_EUR_PACKAGE_SUCCESS,
  createdEurPackage,
});

export const createEurPackageFail = (eurPackageCreateError: HttpError) => ({
  type: actionTypes.CREATE_EUR_PACKAGE_FAIL,
  eurPackageCreateError,
});

export const updateEurPackageStart = () => ({
  type: actionTypes.UPDATE_EUR_PACKAGE_START,
});

export const updateEurPackageSuccess = () => ({
  type: actionTypes.UPDATE_EUR_PACKAGE_SUCCESS,
});

export const updateEurPackageFail = (eurPackageUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_EUR_PACKAGE_FAIL,
  eurPackageUpdateError,
});

export const deleteEurPackageStart = () => ({
  type: actionTypes.DELETE_EUR_PACKAGE_START,
});

export const deleteEurPackageSuccess = () => ({
  type: actionTypes.DELETE_EUR_PACKAGE_SUCCESS,
});

export const deleteEurPackageFail = (eurPackageDeleteError: HttpError) => ({
  type: actionTypes.DELETE_EUR_PACKAGE_FAIL,
  eurPackageDeleteError,
});

export const resetEurPackageStore = () => ({
  type: actionTypes.RESET_EUR_PACKAGE_STORE,
});

export const resetPublicEurPackagesList = () => ({
  type: actionTypes.RESET_PUBLIC_EUR_PACKAGES_LIST,
});
