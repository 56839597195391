import * as actionTypes from './actionTypes';
import { BalanceHistoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { BalanceHistory } from '../../domain/BalanceHistory';

export type BalanceHistoryStateType = {
  userBalanceHistoryList: ListResults<BalanceHistory> | null;
  userBalanceHistoryListLoading: boolean;
  userBalanceHistoryListError: HttpError;
};

export type BalanceHistoryActionType = BalanceHistoryStateType & {
  type: BalanceHistoryActionTypes;
};

export const initialState: BalanceHistoryStateType = {
  userBalanceHistoryList: null,
  userBalanceHistoryListLoading: false,
  userBalanceHistoryListError: null,
};

const fetchUserBalanceHistoryListStart = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...state,
  userBalanceHistoryListLoading: true,
});

const fetchUserBalanceHistoryListSuccess = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  userBalanceHistoryList: action.userBalanceHistoryList,
  userBalanceHistoryListLoading: false,
  userBalanceHistoryListError: null,
});

const fetchUserBalanceHistoryListFail = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  userBalanceHistoryListError: action.userBalanceHistoryListError,
  userBalanceHistoryListLoading: false,
});

const resetBalanceHistoryStore = (): BalanceHistoryStateType => ({
  ...initialState,
});

const completelyResetBalanceHistoryStore = (): BalanceHistoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BalanceHistoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_BALANCE_HISTORY_LIST_START:
      return fetchUserBalanceHistoryListStart(state);
    case actionTypes.FETCH_USER_BALANCE_HISTORY_LIST_SUCCESS:
      return fetchUserBalanceHistoryListSuccess(state, action);
    case actionTypes.FETCH_USER_BALANCE_HISTORY_LIST_FAIL:
      return fetchUserBalanceHistoryListFail(state, action);
    case actionTypes.RESET_BALANCE_HISTORY_STORE:
      return resetBalanceHistoryStore();
    case actionTypes.LOGOUT:
      return completelyResetBalanceHistoryStore();
    default:
      return state;
  }
};

export default reducer;
