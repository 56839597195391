import React, { useEffect, useMemo } from 'react';
import styles from './ShopItemPurchase.module.scss';
import { translate } from '../../../utility/messageTranslator/translate';
import { IntlShape, useIntl } from 'react-intl';
import { Item } from 'src/domain/Item';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import Button from 'src/common/Button/Button';
import { OrderCreateRequest } from 'src/store/order/service';
import * as orderService from '../../../store/order/service';
import { StoreState } from 'src/config/StoreProvider/StoreProvider';
import { User } from 'src/domain/User';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/config/Router/routes';
import { resetOrderStore } from 'src/store/order/actions';
import cartIcon from '../../../assets/icons/cart.svg';
import { ReactSVG } from 'react-svg';
import { DirectPaymentRequest } from '../../../store/payment/service';
import * as paymentService from '../../../store/payment/service';
import { PaymentType } from '../../../domain/Payment';
import { Order } from '../../../domain/Order';
import { navigateToUrl } from '../../../utility/url/urlHelper';

type Props = {
  item?: Item;
  onCreate: (inputs: OrderCreateRequest) => void;
  user: User | null;
  itemQuantity?: number;
  orderCreateSuccess: boolean;
  onResetOrderStore: () => void;
  isLoading: boolean;
  itemVariantId?: number;
  onDirectPaymentCreate: (
    params: DirectPaymentRequest,
    intl: IntlShape,
  ) => void;
  isDirectPayment: boolean;
  isSuccess?: boolean;
  paymentCreateSuccess: boolean;
  paymentSuccessRedirect: string | null;
  onSuccess?: () => void;
  createdOrder: Order | null;
};

export const ShopItemPurchase = ({
  item,
  onCreate,
  user,
  itemQuantity,
  orderCreateSuccess,
  onResetOrderStore,
  isLoading,
  itemVariantId,
  onDirectPaymentCreate,
  isDirectPayment,
  isSuccess,
  paymentCreateSuccess,
  paymentSuccessRedirect,
  onSuccess,
  createdOrder,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const getFinalPrice = (item: Item, itemQuantity: number) => {
    if (item.itemVariantGroups) {
      for (const itemVariantGroup of item.itemVariantGroups) {
        if (itemVariantGroup.itemVariants) {
          for (const itemVariant of itemVariantGroup.itemVariants) {
            if (itemVariant.id === itemVariantId) {
              return itemQuantity * itemVariant.price;
            }
          }
        }
      }
    }

    return itemQuantity * item.price;
  };

  const handleSubmit = () => {
    if (!user) {
      return navigate(routes.login, { replace: true });
    }

    if (!item || !itemQuantity) {
      return;
    }

    onCreate({
      itemId: item.id,
      userId: user.id,
      quantity: itemQuantity,
      itemVariantId: itemVariantId,
      isDirectPayment: isDirectPayment,
    });
  };

  useEffect(() => {
    return () => onResetOrderStore();
  }, []);

  useEffect(() => {
    if (orderCreateSuccess && isDirectPayment && item && itemQuantity) {
      onDirectPaymentCreate(
        {
          finalPrice: getFinalPrice(item, itemQuantity),
          paymentType: PaymentType.PURCHASE,
          createdOrderId: createdOrder?.id,
        },
        intl,
      );
    }
    localStorage.setItem('currentPage', window.location.pathname);
  }, [orderCreateSuccess]);

  const isSuccessMessageVisible = useMemo(() => {
    return (orderCreateSuccess && !isDirectPayment) || isSuccess !== undefined;
  }, [orderCreateSuccess, isDirectPayment, isSuccess]);

  useEffect(() => {
    if (paymentCreateSuccess && paymentSuccessRedirect) {
      onSuccess?.();
      navigateToUrl(paymentSuccessRedirect);
    }
  }, [paymentCreateSuccess]);

  const getSubtitle = () => {
    if (!isDirectPayment || isSuccess) {
      return translate(intl, 'PURCHASE_MODAL.CONFIRMATION_MODAL_SUBTITLE');
    }

    return translate(intl, 'PURCHASE_MODAL.SUBTITLE_FAILED');
  };

  const getStatement = () => {
    if (!isDirectPayment || isSuccess) {
      return translate(intl, 'PURCHASE_MODAL.THANK_YOU');
    }
    return translate(intl, 'PURCHASE_MODAL.STATEMENT_FAILED');
  };

  return (
    <>
      {!orderCreateSuccess && item && itemQuantity && (
        <>
          <div className={styles.content}>
            {translate(intl, 'PURCHASE_MODAL.ORDER')}
          </div>
          <div className={styles.orderDetails}>
            <div>
              {translate(intl, 'PURCHASE_MODAL.ORDER_ITEMS')} {itemQuantity}{' '}
              {item.name}
            </div>
            <div className={styles.price}>
              {`${translate(
                intl,
                'PURCHASE_MODAL.ORDER_ITEMS_PRICE',
              )} ${getFinalPrice(item, itemQuantity)}`}{' '}
              <span className={styles.eur}>{`${translate(
                intl,
                'PURCHASE_MODAL.EUR',
              )}`}</span>
            </div>
          </div>
          <div className={styles.confirm}>
            <Button
              onClick={() => handleSubmit()}
              isLoadingButton
              isLoading={isLoading}
            >
              <span>{translate(intl, 'PURCHASE_MODAL.PURCHASE')}</span>
              <ReactSVG className={styles.cartIcon} src={cartIcon} />
            </Button>
          </div>
        </>
      )}
      {isSuccessMessageVisible && (
        <div className={styles.content}>
          <p className={styles.contentTitle}>
            {translate(intl, 'PURCHASE_MODAL.ORDER')}
          </p>
          <p className={styles.contentSubTitle}>{getSubtitle()}</p>
          <p className={styles.contentStatement}> {getStatement()}</p>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.user.currentUser,
  orderCreateSuccess: state.order.orderCreateSuccess,
  createdOrder: state.order.createdOrder,
  isLoading: state.order.orderCreateLoading,
  paymentCreateSuccess: state.payment.directPaymentCreateSuccess,
  paymentSuccessRedirect: state.payment.directPaymentSuccessRedirect,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCreate: (inputs: OrderCreateRequest) =>
    dispatch(orderService.createOrder(inputs)),
  onResetOrderStore: () => dispatch(resetOrderStore()),
  onDirectPaymentCreate: (params: DirectPaymentRequest, intl: IntlShape) =>
    dispatch(paymentService.createDirectPayment(params, intl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopItemPurchase);
