export const FETCH_EUR_PACKAGES_START = 'FETCH_EUR_PACKAGES_START';
export const FETCH_EUR_PACKAGES_SUCCESS = 'FETCH_EUR_PACKAGES_SUCCESS';
export const FETCH_EUR_PACKAGES_FAIL = 'FETCH_EUR_PACKAGES_FAIL';

export const FETCH_PUBLIC_EUR_PACKAGES_START =
  'FETCH_PUBLIC_EUR_PACKAGES_START';
export const FETCH_PUBLIC_EUR_PACKAGES_SUCCESS =
  'FETCH_PUBLIC_EUR_PACKAGES_SUCCESS';
export const FETCH_PUBLIC_EUR_PACKAGES_FAIL = 'FETCH_PUBLIC_EUR_PACKAGES_FAIL';

export const FETCH_EUR_PACKAGE_START = 'FETCH_EUR_PACKAGE_START';
export const FETCH_EUR_PACKAGE_SUCCESS = 'FETCH_EUR_PACKAGE_SUCCESS';
export const FETCH_EUR_PACKAGE_FAIL = 'FETCH_EUR_PACKAGE_FAIL';

export const CREATE_EUR_PACKAGE_START = 'CREATE_EUR_PACKAGE_START';
export const CREATE_EUR_PACKAGE_SUCCESS = 'CREATE_EUR_PACKAGE_SUCCESS';
export const CREATE_EUR_PACKAGE_FAIL = 'CREATE_EUR_PACKAGE_FAIL';

export const UPDATE_EUR_PACKAGE_START = 'UPDATE_EUR_PACKAGE_START';
export const UPDATE_EUR_PACKAGE_SUCCESS = 'UPDATE_EUR_PACKAGE_SUCCESS';
export const UPDATE_EUR_PACKAGE_FAIL = 'UPDATE_EUR_PACKAGE_FAIL';

export const DELETE_EUR_PACKAGE_START = 'DELETE_EUR_PACKAGE_START';
export const DELETE_EUR_PACKAGE_SUCCESS = 'DELETE_EUR_PACKAGE_SUCCESS';
export const DELETE_EUR_PACKAGE_FAIL = 'DELETE_EUR_PACKAGE_FAIL';

export const RESET_EUR_PACKAGE_STORE = 'RESET_EUR_PACKAGE_STORE';
export const RESET_PUBLIC_EUR_PACKAGES_LIST = 'RESET_PUBLIC_EUR_PACKAGES_LIST';

export const LOGOUT = 'LOGOUT';

export type EurPackageActionTypes =
  | typeof FETCH_EUR_PACKAGES_START
  | typeof FETCH_EUR_PACKAGES_SUCCESS
  | typeof FETCH_EUR_PACKAGES_FAIL
  | typeof FETCH_PUBLIC_EUR_PACKAGES_START
  | typeof FETCH_PUBLIC_EUR_PACKAGES_SUCCESS
  | typeof FETCH_PUBLIC_EUR_PACKAGES_FAIL
  | typeof FETCH_EUR_PACKAGE_START
  | typeof FETCH_EUR_PACKAGE_SUCCESS
  | typeof FETCH_EUR_PACKAGE_FAIL
  | typeof CREATE_EUR_PACKAGE_START
  | typeof CREATE_EUR_PACKAGE_SUCCESS
  | typeof CREATE_EUR_PACKAGE_FAIL
  | typeof UPDATE_EUR_PACKAGE_START
  | typeof UPDATE_EUR_PACKAGE_SUCCESS
  | typeof UPDATE_EUR_PACKAGE_FAIL
  | typeof DELETE_EUR_PACKAGE_START
  | typeof DELETE_EUR_PACKAGE_SUCCESS
  | typeof DELETE_EUR_PACKAGE_FAIL
  | typeof RESET_EUR_PACKAGE_STORE
  | typeof RESET_PUBLIC_EUR_PACKAGES_LIST
  | typeof LOGOUT;
