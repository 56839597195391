import React, { useEffect, useState } from 'react';
import styles from './PublicNavigation.module.scss';
import { IconButton } from '@mui/material';
import {
  faBars,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/squashlogo.png';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import Button from '../Button/Button';
import cx from 'classnames';
import { NavigationItem } from '../Layout/Layout';
import { User } from '../../domain/User';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Locale } from '../../domain/Translation';
import { connect } from 'react-redux';
import iconUser from '../../assets/icons/user.svg';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import Modal from '../Modal/Modal';
import EurPackageListModal from '../../component/EurPackage/EurPackageListModal/EurPackageListModal';
import * as userService from '../../store/user/service';

export type Props = {
  user: User | null;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  navigationItems: NavigationItem[];
  selectedLocale: Locale;
  isAuthenticated: boolean;
  onLogout: () => void;
  onFetchCurrentUser: () => void;
  isUserUpdateNeeded: boolean;
};

const MOBILE_BREAK_POINT = 1024;

export const PublicNavigation = ({
  user,
  onDrawerClick,
  isMobileMenuOpen,
  navigationItems,
  selectedLocale,
  isAuthenticated,
  onLogout,
  onFetchCurrentUser,
  isUserUpdateNeeded,
}: Props) => {
  const { width } = useWindowSize();
  const intl = useIntl();
  const navigate = useNavigate();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isTopUpEurModalOpen, setIsTopUpEurModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const intervalRef = setInterval(() => {
      onFetchCurrentUser();
    }, 5000);

    return () => clearInterval(intervalRef);
  }, [isAuthenticated]);

  const LIGHT_HEADER_ROUTES = [
    routes.homepage,
    getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
      ':slug',
      translate(intl, 'NAVIGATION.SHOP_LINK').toLowerCase(),
    ),
  ];

  const isLightNavigation = LIGHT_HEADER_ROUTES.includes(location.pathname);

  const handleCancel = () => {
    setIsTopUpEurModalOpen(false);
  };

  useEffect(() => {
    if (isUserUpdateNeeded) {
      onFetchCurrentUser();
    }
  }, [isUserUpdateNeeded]);

  const getActions = () => {
    return (
      <>
        {isTopUpEurModalOpen && (
          <Modal onClose={handleCancel} isOpen={isTopUpEurModalOpen} isBig>
            <EurPackageListModal onClose={handleCancel} />
          </Modal>
        )}
        <div className={styles.actionContainer}>
          {isAuthenticated && (
            <div
              onClick={() => setIsTopUpEurModalOpen(true)}
              className={styles.userWalletContainer}
            >
              <span
                className={cx({
                  [styles.walletAmount]: !isLightNavigation,
                  [styles.lightWalletAmount]: isLightNavigation,
                })}
              >
                {(user && user.profile?.eurAmount) ?? 0} €
              </span>
            </div>
          )}
          <div
            className={cx(styles.iconButtonsContainer, {
              [styles.lightIcons]: isLightNavigation,
            })}
          >
            <Link to={isAuthenticated ? routes.profile.details : routes.login}>
              <ReactSVG
                src={iconUser}
                className={styles.iconUser}
                beforeInjection={(svg) => {
                  isLightNavigation
                    ? svg.setAttribute('style', 'color: #FFFFFF')
                    : svg.setAttribute('style', 'color: #3D3F40');
                }}
              />
            </Link>
            {isAuthenticated && (
              <FontAwesomeIcon
                className={cx({
                  [styles.logoutButton]: !isLightNavigation,
                  [styles.lightLogout]: isLightNavigation,
                })}
                icon={faSignOutAlt as IconProp}
                onClick={() => {
                  onLogout();
                  navigate(routes.homepage);
                }}
              />
            )}
          </div>
          {width && width >= MOBILE_BREAK_POINT && (
            <Button
              buttonVariant="contained"
              color="primary"
              onClick={() =>
                navigate(getLocaleUrl(routes.bookTime, selectedLocale))
              }
            >
              {translate(intl, 'NAVIGATION.BOOK_TIME')}
            </Button>
          )}
        </div>
      </>
    );
  };

  const handlePopoverState = () => setIsPopoverOpen((prev) => !prev);

  return (
    <>
      <header
        className={cx(styles.navigationContainer, {
          [styles.isPopoverOpen]: isPopoverOpen,
        })}
      >
        <div
          className={cx(styles.innerNavigation, {
            [styles.lightBorder]: isLightNavigation,
          })}
        >
          {width && width >= MOBILE_BREAK_POINT && (
            <div
              className={cx(styles.navigationItems, {
                [styles.lightLinks]: isLightNavigation,
              })}
            >
              {navigationItems.map((item) => (
                <Link
                  key={item.label}
                  to={item.to}
                  className={cx({
                    [styles.isActive]: location.pathname === item.to,
                  })}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          )}

          <Link to={getLocaleUrl(routes.homepage, selectedLocale)}>
            <img src={logo} alt="" className={styles.logoImage} />
          </Link>

          <div className={styles.iconItems}>
            {getActions()}
            <LanguageSwitcher
              isPopoverOpen={isPopoverOpen}
              handlePopoverState={handlePopoverState}
              isLightNavigation={isLightNavigation}
            />
            {width && width < MOBILE_BREAK_POINT && (
              <IconButton onClick={() => onDrawerClick()}>
                <FontAwesomeIcon
                  className={cx(styles.drawerIcon, {
                    [styles.lightIcon]: isLightNavigation,
                  })}
                  icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                  fixedWidth
                  size="sm"
                />
              </IconButton>
            )}
          </div>

          {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
            <div className={styles.mobileMenu}>
              {navigationItems.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
              <NavLink
                to={getLocaleUrl(routes.bookTime, selectedLocale)}
                className={({ isActive }) =>
                  cx(styles.navigationItem, {
                    [styles.activeSubItem]: isActive,
                  })
                }
              >
                {translate(intl, 'NAVIGATION.BOOK_TIME')}
              </NavLink>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.user.currentUser,
  selectedLocale: state.auth.selectedLocale,
  isUserUpdateNeeded: state.order.userUpdateNeeded,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
