import { ListResults } from 'src/common/List/List';
import { HttpError } from 'src/config/Axios/axios-instance';
import { Payment, PaymentType } from '../../domain/Payment';
import * as actionTypes from './actionTypes';

export const fetchPaymentStart = () => ({
  type: actionTypes.FETCH_PAYMENT_START,
});

export const fetchPaymentSuccess = (payment: Payment) => ({
  type: actionTypes.FETCH_PAYMENT_SUCCESS,
  payment,
});

export const fetchPaymentFail = (paymentError: string) => ({
  type: actionTypes.FETCH_PAYMENT_FAIL,
  paymentError,
});

export const fetchPaymentListStart = () => ({
  type: actionTypes.FETCH_PAYMENT_LIST_START,
});

export const fetchPaymentListSuccess = (paymentList: ListResults<Payment>) => ({
  type: actionTypes.FETCH_PAYMENT_LIST_SUCCESS,
  paymentList,
});

export const fetchPaymentListFail = (paymentListError: HttpError) => ({
  type: actionTypes.FETCH_PAYMENT_LIST_FAIL,
  paymentListError,
});

export const createTopUpStart = () => ({
  type: actionTypes.CREATE_TOP_UP_START,
});

export const createTopUpSuccess = (topUpSuccessRedirect: string) => ({
  type: actionTypes.CREATE_TOP_UP_SUCCESS,
  topUpSuccessRedirect,
});

export const createTopUpFail = (topUpCreateError: string) => ({
  type: actionTypes.CREATE_TOP_UP_FAIL,
  topUpCreateError,
});

export const createDirectPaymentStart = () => ({
  type: actionTypes.CREATE_DIRECT_PAYMENT_START,
});

export const createDirectPaymentSuccess = (
  directPaymentSuccessRedirect: string,
) => ({
  type: actionTypes.CREATE_DIRECT_PAYMENT_SUCCESS,
  directPaymentSuccessRedirect,
});

export const createDirectPaymentFail = (directPaymentCreateError: string) => ({
  type: actionTypes.CREATE_DIRECT_PAYMENT_FAIL,
  directPaymentCreateError,
});

export const setIsPaymentStatusSuccess = (status: boolean) => ({
  type: actionTypes.SET_IS_PAYMENT_STATUS_SUCCESS,
  status,
});

export const setPaymentType = (paymentType: PaymentType) => ({
  type: actionTypes.SET_PAYMENT_TYPE,
  paymentType,
});

export const exportPaymentsStart = () => ({
  type: actionTypes.EXPORT_PAYMENTS_START,
});

export const exportPaymentsSuccess = (exportPayments: Payment[]) => ({
  type: actionTypes.EXPORT_PAYMENTS_SUCCESS,
  exportPayments,
});

export const exportPaymentsFail = (exportPaymentsError: string) => ({
  type: actionTypes.EXPORT_PAYMENTS_FAIL,
  exportPaymentsError,
});

export const resetExportPayments = () => ({
  type: actionTypes.RESET_EXPORT_PAYMENTS,
});

export const resetTopUpSuccessStatus = () => ({
  type: actionTypes.RESET_TOP_UP_SUCCESS_STATUS,
});

export const resetDirectPaymentSuccessStatus = () => ({
  type: actionTypes.RESET_DIRECT_PAYMENT_SUCCESS_STATUS,
});

export const resetPaymentStatus = () => ({
  type: actionTypes.RESET_PAYMENT_STATUS,
});

export const resetPaymentStore = () => ({
  type: actionTypes.RESET_PAYMENT_STORE,
});
