import { Slot } from '../../domain/Slot';
import { EventSourceInput } from '@fullcalendar/common';
import moment from 'moment';
import { User } from '../../domain/User';

export const mapSlotsIntoEvents = (slots: Slot[]): EventSourceInput =>
  slots.map((slot) => {
    return {
      allDay: false,
      end: slot.dateTo,
      id: slot.id.toString(),
      start: slot.date,
      textColor: '#FFFFFF',
      title: `${slot.time} - ${getFormattedSlotEnd(slot)} | ${
        slot.location?.name ?? '-'
      }${getReservationInfo(slot)}`,
      backgroundColor: `${getSlotColor(slot)}`,
      borderColor: `${getSlotColor(slot)}`,
    };
  });

export const getSlotColor = (slot: Slot) => {
  if (
    slot.reservation &&
    slot.reservation?.paymentId &&
    !slot.reservation?.isPurchasedWithCredits &&
    !slot.reservation?.payment?.isPaid
  ) {
    return '#296ddb';
  }

  if (slot.reservation && !slot.reservation.isCanceled) {
    return '#DB2955';
  } else if (slot.reservation && slot.reservation.isCanceled) {
    return '#E49B0F';
  }

  return '#2E933C';
};

export const getFormattedSlotEnd = (slot: Slot) =>
  moment(slot?.time, 'HH:mm')
    .add(moment.duration(slot?.duration))
    .format('HH:mm');

export const getReservationInfo = (slot: Slot) => {
  if (!slot.reservation || (slot.reservation && slot.reservation.isCanceled)) {
    return '';
  }

  const profile = slot.reservation.user?.profile;

  return ` | ${profile?.firstName} ${profile?.lastName}`;
};

export const getReservationFormattedInfo = (user: User) => {
  const profile = user.profile;

  return `${profile?.firstName} ${profile?.lastName}`;
};

export const isMomentToday = (momentDay: moment.Moment) =>
  momentDay.isSame(moment(), 'day');

export const getDurationHours = (slot: Slot) =>
  moment(slot.duration, 'HH:mm').hours();

export const getDurationMinutes = (slot: Slot) =>
  moment(slot.duration, 'HH:mm').minutes();

export const getSlotLocation = (slot: Slot) =>
  `${slot.location?.name ?? '-'} - ${
    slot.location?.description ?? '-'
  } - ${slot.price.toFixed(0)} €`;
