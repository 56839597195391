import * as actionTypes from './actionTypes';
import { WorkingHourActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { WorkingHour } from '../../domain/WorkingHour';
import { ListResults } from '../../common/List/List';

export type WorkingHourStateType = {
  workingHours: ListResults<WorkingHour> | null;
  workingHoursLoading: boolean;
  workingHoursError: HttpError;
  workingHourCreateLoading: boolean;
  workingHourCreateError: HttpError;
  workingHourCreateSuccess: boolean;
  workingHourUpdateLoading: boolean;
  workingHourUpdateError: HttpError;
  workingHourUpdateSuccess: boolean;
  workingHourDeleteError: HttpError;
  workingHourDeleteSuccess: boolean;
  workingHourDeleteLoading: boolean;
  workingHoursListUpdateNeeded: boolean;
};

export type WorkingHourActionType = WorkingHourStateType & {
  type: WorkingHourActionTypes;
};

export const initialState: WorkingHourStateType = {
  workingHours: null,
  workingHoursLoading: true,
  workingHoursError: null,
  workingHourCreateLoading: false,
  workingHourCreateError: null,
  workingHourCreateSuccess: false,
  workingHourUpdateLoading: false,
  workingHourUpdateError: null,
  workingHourUpdateSuccess: false,
  workingHourDeleteLoading: false,
  workingHourDeleteError: null,
  workingHourDeleteSuccess: false,
  workingHoursListUpdateNeeded: false,
};

const fetchCategoriesStart = (
  state: WorkingHourStateType,
): WorkingHourStateType => ({
  ...state,
  workingHoursLoading: true,
});

const fetchCategoriesSuccess = (
  state: WorkingHourStateType,
  action: WorkingHourActionType,
): WorkingHourStateType => ({
  ...state,
  workingHours: action.workingHours,
  workingHoursLoading: false,
  workingHoursError: null,
  workingHoursListUpdateNeeded: false,
});

const fetchCategoriesFail = (
  state: WorkingHourStateType,
  action: WorkingHourActionType,
): WorkingHourStateType => ({
  ...state,
  workingHoursError: action.workingHoursError,
  workingHoursLoading: false,
});

const createWorkingHourStart = (
  state: WorkingHourStateType,
): WorkingHourStateType => ({
  ...state,
  workingHourCreateLoading: true,
});

const createWorkingHourSuccess = (
  state: WorkingHourStateType,
  action: WorkingHourActionType,
): WorkingHourStateType => ({
  ...state,
  workingHourCreateLoading: false,
  workingHourCreateError: null,
  workingHourCreateSuccess: true,
  workingHoursListUpdateNeeded: true,
});

const createWorkingHourFail = (
  state: WorkingHourStateType,
  action: WorkingHourActionType,
): WorkingHourStateType => ({
  ...state,
  workingHourCreateLoading: false,
  workingHourCreateError: action.workingHourCreateError,
});

const updateWorkingHourStart = (
  state: WorkingHourStateType,
): WorkingHourStateType => ({
  ...state,
  workingHourUpdateLoading: true,
});

const updateWorkingHourSuccess = (
  state: WorkingHourStateType,
): WorkingHourStateType => ({
  ...state,
  workingHourUpdateLoading: false,
  workingHourUpdateError: null,
  workingHourUpdateSuccess: true,
  workingHoursListUpdateNeeded: true,
});

const updateWorkingHourFail = (
  state: WorkingHourStateType,
  action: WorkingHourActionType,
): WorkingHourStateType => ({
  ...state,
  workingHourUpdateLoading: false,
  workingHourUpdateError: action.workingHourUpdateError,
});

const deleteWorkingHourStart = (
  state: WorkingHourStateType,
): WorkingHourStateType => ({
  ...state,
  workingHourDeleteLoading: true,
});

const deleteWorkingHourSuccess = (
  state: WorkingHourStateType,
): WorkingHourStateType => ({
  ...state,
  workingHourDeleteLoading: false,
  workingHourDeleteError: null,
  workingHourDeleteSuccess: true,
  workingHoursListUpdateNeeded: true,
});

const deleteWorkingHourFail = (
  state: WorkingHourStateType,
  action: WorkingHourActionType,
): WorkingHourStateType => ({
  ...state,
  workingHourDeleteLoading: false,
  workingHourDeleteError: action.workingHourDeleteError,
});

const resetWorkingHourStore = (
  state: WorkingHourStateType,
): WorkingHourStateType => ({
  ...initialState,
});

const completelyResetWorkingHourStore = (): WorkingHourStateType => ({
  ...initialState,
});

const resetWorkingHoursSuccessState = (): WorkingHourStateType => ({
  ...initialState,
  workingHourCreateSuccess: false,
  workingHourUpdateSuccess: false,
  workingHourDeleteSuccess: false,
});

const reducer = (state = initialState, action: WorkingHourActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WORKING_HOURS_START:
      return fetchCategoriesStart(state);
    case actionTypes.FETCH_WORKING_HOURS_SUCCESS:
      return fetchCategoriesSuccess(state, action);
    case actionTypes.FETCH_WORKING_HOURS_FAIL:
      return fetchCategoriesFail(state, action);
    case actionTypes.CREATE_WORKING_HOUR_START:
      return createWorkingHourStart(state);
    case actionTypes.CREATE_WORKING_HOUR_SUCCESS:
      return createWorkingHourSuccess(state, action);
    case actionTypes.CREATE_WORKING_HOUR_FAIL:
      return createWorkingHourFail(state, action);
    case actionTypes.UPDATE_WORKING_HOUR_START:
      return updateWorkingHourStart(state);
    case actionTypes.UPDATE_WORKING_HOUR_SUCCESS:
      return updateWorkingHourSuccess(state);
    case actionTypes.UPDATE_WORKING_HOUR_FAIL:
      return updateWorkingHourFail(state, action);
    case actionTypes.DELETE_WORKING_HOUR_START:
      return deleteWorkingHourStart(state);
    case actionTypes.DELETE_WORKING_HOUR_SUCCESS:
      return deleteWorkingHourSuccess(state);
    case actionTypes.DELETE_WORKING_HOUR_FAIL:
      return deleteWorkingHourFail(state, action);
    case actionTypes.RESET_WORKING_HOUR_STORE:
      return resetWorkingHourStore(state);
    case actionTypes.RESET_WORKING_SUCCESS_STATE:
      return resetWorkingHoursSuccessState();
    case actionTypes.LOGOUT:
      return completelyResetWorkingHourStore();
    default:
      return state;
  }
};

export default reducer;
