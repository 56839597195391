export const CREATE_ITEM_VARIANT_GROUP_START =
  'CREATE_ITEM_VARIANT_GROUP_START';
export const CREATE_ITEM_VARIANT_GROUP_SUCCESS =
  'CREATE_ITEM_VARIANT_GROUP_SUCCESS';
export const CREATE_ITEM_VARIANT_GROUP_FAIL = 'CREATE_ITEM_VARIANT_GROUP_FAIL';

export const UPDATE_ITEM_VARIANT_GROUP_START =
  'UPDATE_ITEM_VARIANT_GROUP_START';
export const UPDATE_ITEM_VARIANT_GROUP_SUCCESS =
  'UPDATE_ITEM_VARIANT_GROUP_SUCCESS';
export const UPDATE_ITEM_VARIANT_GROUP_FAIL = 'UPDATE_ITEM_VARIANT_GROUP_FAIL';

export const DELETE_ITEM_VARIANT_GROUP_START =
  'DELETE_ITEM_VARIANT_GROUP_START';
export const DELETE_ITEM_VARIANT_GROUP_SUCCESS =
  'DELETE_ITEM_VARIANT_GROUP_SUCCESS';
export const DELETE_ITEM_VARIANT_GROUP_FAIL = 'DELETE_ITEM_VARIANT_GROUP_FAIL';

export const FETCH_ITEM_VARIANT_GROUPS_START =
  'FETCH_ITEM_VARIANT_GROUPS_START';
export const FETCH_ITEM_VARIANT_GROUPS_SUCCESS =
  'FETCH_ITEM_VARIANT_GROUPS_SUCCESS';
export const FETCH_ITEM_VARIANT_GROUPS_FAIL = 'FETCH_ITEM_VARIANT_GROUPS_FAIL';

export const RESET_ITEM_VARIANT_GROUP_STORE = 'RESET_ITEM_VARIANT_GROUP_STORE';

export type ItemVariantGroupActionTypes =
  | typeof CREATE_ITEM_VARIANT_GROUP_START
  | typeof CREATE_ITEM_VARIANT_GROUP_SUCCESS
  | typeof CREATE_ITEM_VARIANT_GROUP_FAIL
  | typeof UPDATE_ITEM_VARIANT_GROUP_START
  | typeof UPDATE_ITEM_VARIANT_GROUP_SUCCESS
  | typeof UPDATE_ITEM_VARIANT_GROUP_FAIL
  | typeof DELETE_ITEM_VARIANT_GROUP_START
  | typeof DELETE_ITEM_VARIANT_GROUP_SUCCESS
  | typeof DELETE_ITEM_VARIANT_GROUP_FAIL
  | typeof FETCH_ITEM_VARIANT_GROUPS_START
  | typeof FETCH_ITEM_VARIANT_GROUPS_SUCCESS
  | typeof FETCH_ITEM_VARIANT_GROUPS_FAIL
  | typeof RESET_ITEM_VARIANT_GROUP_STORE;
