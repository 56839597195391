import { User } from './User';
import { Invoice } from './Invoice';

export interface Payment {
  id: number;
  isPaid: boolean;
  createdAt: string;
  paymentNumber: number;
  user: User;
  price: number;
  invoice?: Invoice;
  wasExported: boolean;
  paymentType: PaymentType;
}

export enum PaymentType {
  // eslint-disable-next-line no-unused-vars
  BALANCE_TOP_UP = 'BALANCE_TOP_UP',
  // eslint-disable-next-line no-unused-vars
  RESERVATION = 'RESERVATION',
  // eslint-disable-next-line no-unused-vars
  PURCHASE = 'PURCHASE',
}
