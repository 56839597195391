import * as actionTypes from './actionTypes';
import { CategoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Category, CategoryOption } from '../../domain/Category';
import { ListResults } from '../../common/List/List';

export type CategoryStateType = {
  categoriesList: ListResults<Category> | null;
  categoriesLoading: boolean;
  categoriesError: HttpError;
  categoriesListUpdateNeeded: boolean;
  categoryCreateLoading: boolean;
  categoryCreateError: HttpError;
  categoryCreateSuccess: boolean;
  createdCategory: Category | null;
  categoryUpdateLoading: boolean;
  categoryUpdateError: HttpError;
  categoryUpdateSuccess: boolean;
  categoryDeleteLoading: boolean;
  categoryDeleteError: HttpError;
  categoryDeleteSuccess: boolean;
  categoryOptions: CategoryOption[] | null;
  categoryOptionsLoading: boolean;
  categoryOptionsError: HttpError;
  category: Category | null;
  categoryLoading: boolean;
  categoryError: HttpError;
  publicCategories: ListResults<Category> | null;
  publicCategoriesLoading: boolean;
  publicCategoriesError: HttpError;
};

export type CategoryActionType = CategoryStateType & {
  type: CategoryActionTypes;
};

export const initialState: CategoryStateType = {
  categoriesList: null,
  categoriesLoading: true,
  categoriesError: null,
  categoriesListUpdateNeeded: false,
  categoryCreateLoading: false,
  categoryCreateError: null,
  categoryCreateSuccess: false,
  createdCategory: null,
  categoryUpdateLoading: false,
  categoryUpdateError: null,
  categoryUpdateSuccess: false,
  categoryDeleteLoading: false,
  categoryDeleteError: null,
  categoryDeleteSuccess: false,
  categoryOptions: null,
  categoryOptionsError: null,
  categoryOptionsLoading: false,
  category: null,
  categoryLoading: false,
  categoryError: null,
  publicCategories: null,
  publicCategoriesLoading: false,
  publicCategoriesError: null,
};

const fetchCategoriesStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoriesLoading: true,
});

const fetchCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesList: action.categoriesList,
  categoriesLoading: false,
  categoriesError: null,
  categoriesListUpdateNeeded: false,
});

const fetchCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesError: action.categoriesError,
  categoriesLoading: false,
});

const fetchCategoryOptionsStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryOptionsLoading: true,
});

const fetchCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryLoading: true,
  categoryCreateSuccess: false,
  categoryCreateError: null,
});

const fetchCategorySuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  category: action.category,
  categoryLoading: false,
  categoryError: null,
});

const fetchCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryError: action.categoryError,
  categoryLoading: false,
});

const fetchCategoryOptionsSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryOptions: action.categoryOptions,
  categoryOptionsLoading: false,
  categoryOptionsError: null,
});

const fetchCategoryOptionsFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryOptionsError: action.categoryOptionsError,
  categoryOptionsLoading: false,
});

const createCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryCreateLoading: true,
});

const createCategorySuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryCreateLoading: false,
  categoryCreateError: null,
  categoryCreateSuccess: true,
  createdCategory: action.createdCategory,
  categoriesListUpdateNeeded: true,
});

const createCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryCreateLoading: false,
  categoryCreateError: action.categoryCreateError,
});

const updateCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: true,
});

const updateCategorySuccess = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: false,
  categoryUpdateError: null,
  categoryUpdateSuccess: true,
  categoriesListUpdateNeeded: true,
});

const updateCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: false,
  categoryUpdateError: action.categoryUpdateError,
});

const deleteCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: true,
});

const deleteCategorySuccess = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: false,
  categoryDeleteError: null,
  categoryDeleteSuccess: true,
  categoriesListUpdateNeeded: true,
});

const deleteCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: false,
  categoryDeleteError: action.categoryDeleteError,
});

const fetchPublicCategoriesStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  publicCategoriesLoading: true,
});

const fetchPublicCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  publicCategoriesLoading: false,
  publicCategories: action.publicCategories,
});

const fetchPublicCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  publicCategoriesLoading: false,
  publicCategoriesError: action.publicCategoriesError,
});

const resetCategoryStore = (state: CategoryStateType): CategoryStateType => ({
  ...initialState,
});

const completelyResetCategoryStore = (): CategoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CategoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_START:
      return fetchCategoriesStart(state);
    case actionTypes.FETCH_CATEGORIES_SUCCESS:
      return fetchCategoriesSuccess(state, action);
    case actionTypes.FETCH_CATEGORIES_FAIL:
      return fetchCategoriesFail(state, action);
    case actionTypes.FETCH_CATEGORY_START:
      return fetchCategoryStart(state);
    case actionTypes.FETCH_CATEGORY_SUCCESS:
      return fetchCategorySuccess(state, action);
    case actionTypes.FETCH_CATEGORY_FAIL:
      return fetchCategoryFail(state, action);
    case actionTypes.CREATE_CATEGORY_START:
      return createCategoryStart(state);
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return createCategorySuccess(state, action);
    case actionTypes.CREATE_CATEGORY_FAIL:
      return createCategoryFail(state, action);
    case actionTypes.UPDATE_CATEGORY_START:
      return updateCategoryStart(state);
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return updateCategorySuccess(state);
    case actionTypes.UPDATE_CATEGORY_FAIL:
      return updateCategoryFail(state, action);
    case actionTypes.DELETE_CATEGORY_START:
      return deleteCategoryStart(state);
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return deleteCategorySuccess(state);
    case actionTypes.DELETE_CATEGORY_FAIL:
      return deleteCategoryFail(state, action);
    case actionTypes.RESET_CATEGORY_STORE:
      return resetCategoryStore(state);
    case actionTypes.FETCH_CATEGORY_OPTIONS_START:
      return fetchCategoryOptionsStart(state);
    case actionTypes.FETCH_CATEGORY_OPTIONS_SUCCESS:
      return fetchCategoryOptionsSuccess(state, action);
    case actionTypes.FETCH_CATEGORY_OPTIONS_FAIL:
      return fetchCategoryOptionsFail(state, action);
    case actionTypes.FETCH_PUBLIC_CATEGORIES_START:
      return fetchPublicCategoriesStart(state);
    case actionTypes.FETCH_PUBLIC_CATEGORIES_SUCCESS:
      return fetchPublicCategoriesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_CATEGORIES_FAIL:
      return fetchPublicCategoriesFail(state, action);
    case actionTypes.RESET_CATEGORY_STORE:
      return resetCategoryStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCategoryStore();
    default:
      return state;
  }
};

export default reducer;
