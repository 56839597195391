export const FETCH_SLOTS_START = 'FETCH_SLOTS_START';
export const FETCH_SLOTS_SUCCESS = 'FETCH_SLOTS_SUCCESS';
export const FETCH_SLOTS_FAIL = 'FETCH_SLOTS_FAIL';

export const FETCH_PUBLIC_SLOTS_START = 'FETCH_PUBLIC_SLOTS_START';
export const FETCH_PUBLIC_SLOTS_SUCCESS = 'FETCH_PUBLIC_SLOTS_SUCCESS';
export const FETCH_PUBLIC_SLOTS_FAIL = 'FETCH_PUBLIC_SLOTS_FAIL';

export const CREATE_SLOT_START = 'CREATE_SLOT_START';
export const CREATE_SLOT_SUCCESS = 'CREATE_SLOT_SUCCESS';
export const CREATE_SLOT_FAIL = 'CREATE_SLOT_FAIL';

export const UPDATE_SLOT_START = 'UPDATE_SLOTS_START';
export const UPDATE_SLOT_SUCCESS = 'UPDATE_SLOTS_SUCCESS';
export const UPDATE_SLOT_FAIL = 'UPDATE_SLOTS_FAIL';

export const DELETE_SLOT_START = 'DELETE_SLOT_START';
export const DELETE_SLOT_SUCCESS = 'DELETE_SLOT_SUCCESS';
export const DELETE_SLOT_FAIL = 'DELETE_SLOT_FAIL';

export const DELETE_DAY_START = 'DELETE_DAY_START';
export const DELETE_DAY_SUCCESS = 'DELETE_DAY_SUCCESS';
export const DELETE_DAY_FAIL = 'DELETE_DAY_FAIL';

export const SELECT_TIME = 'SELECT_TIME';

export const RESET_SELECT_TIME_STORE = 'RESET_SELECT_TIME_STORE';
export const RESET_SLOTS = 'RESET_SLOTS';

export const LOGOUT = 'LOGOUT';

export type SlotActionTypes =
  | typeof FETCH_SLOTS_START
  | typeof FETCH_SLOTS_SUCCESS
  | typeof FETCH_SLOTS_FAIL
  | typeof FETCH_PUBLIC_SLOTS_START
  | typeof FETCH_PUBLIC_SLOTS_SUCCESS
  | typeof FETCH_PUBLIC_SLOTS_FAIL
  | typeof CREATE_SLOT_START
  | typeof CREATE_SLOT_SUCCESS
  | typeof CREATE_SLOT_FAIL
  | typeof UPDATE_SLOT_START
  | typeof UPDATE_SLOT_SUCCESS
  | typeof UPDATE_SLOT_FAIL
  | typeof DELETE_SLOT_START
  | typeof DELETE_SLOT_SUCCESS
  | typeof DELETE_SLOT_FAIL
  | typeof DELETE_DAY_START
  | typeof DELETE_DAY_SUCCESS
  | typeof DELETE_DAY_FAIL
  | typeof SELECT_TIME
  | typeof RESET_SELECT_TIME_STORE
  | typeof RESET_SLOTS
  | typeof LOGOUT;
