export const CREATE_ITEM_VARIANT_START = 'CREATE_ITEM_VARIANT_START';
export const CREATE_ITEM_VARIANT_SUCCESS = 'CREATE_ITEM_VARIANT_SUCCESS';
export const CREATE_ITEM_VARIANT_FAIL = 'CREATE_ITEM_VARIANT_FAIL';

export const UPDATE_ITEM_VARIANT_START = 'UPDATE_ITEM_VARIANT_START';
export const UPDATE_ITEM_VARIANT_SUCCESS = 'UPDATE_ITEM_VARIANT_SUCCESS';
export const UPDATE_ITEM_VARIANT_FAIL = 'UPDATE_ITEM_VARIANT_FAIL';

export const DELETE_ITEM_VARIANT_START = 'DELETE_ITEM_VARIANT_START';
export const DELETE_ITEM_VARIANT_SUCCESS = 'DELETE_ITEM_VARIANT_SUCCESS';
export const DELETE_ITEM_VARIANT_FAIL = 'DELETE_ITEM_VARIANT_FAIL';

export const UPDATE_ITEM_VARIANTS_PRIORITY_START =
  'UPDATE_ITEM_VARIANTS_PRIORITY_START';
export const UPDATE_ITEM_VARIANTS_PRIORITY_SUCCESS =
  'UPDATE_ITEM_VARIANTS_PRIORITY_SUCCESS';
export const UPDATE_ITEM_VARIANTS_PRIORITY_FAIL =
  'UPDATE_ITEM_VARIANTS_PRIORITY_FAIL';

export const RESET_ITEM_VARIANT_STORE = 'RESET_ITEM_VARIANT_STORE';

export type ItemVariantActionTypes =
  | typeof CREATE_ITEM_VARIANT_START
  | typeof CREATE_ITEM_VARIANT_SUCCESS
  | typeof CREATE_ITEM_VARIANT_FAIL
  | typeof UPDATE_ITEM_VARIANT_START
  | typeof UPDATE_ITEM_VARIANT_SUCCESS
  | typeof UPDATE_ITEM_VARIANT_FAIL
  | typeof DELETE_ITEM_VARIANT_START
  | typeof DELETE_ITEM_VARIANT_SUCCESS
  | typeof DELETE_ITEM_VARIANT_FAIL
  | typeof RESET_ITEM_VARIANT_STORE
  | typeof UPDATE_ITEM_VARIANTS_PRIORITY_START
  | typeof UPDATE_ITEM_VARIANTS_PRIORITY_SUCCESS
  | typeof UPDATE_ITEM_VARIANTS_PRIORITY_FAIL;
