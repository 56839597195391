import * as actionTypes from './actionTypes';
import { ItemVariantGroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ItemVariantGroup } from '../../domain/Item';

export type ItemVariantGroupStateType = {
  itemVariantGroupCreateLoading: boolean;
  itemVariantGroupCreateError: HttpError;
  itemVariantGroupCreateSuccess: boolean;
  itemVariantGroupUpdateLoading: boolean;
  itemVariantGroupUpdateError: HttpError;
  itemVariantGroupUpdateSuccess: boolean;
  itemVariantGroupDeleteLoading: boolean;
  itemVariantGroupDeleteError: HttpError;
  itemVariantGroupDeleteSuccess: boolean;
  itemVariantGroups: ItemVariantGroup[];
  itemVariantGroupsLoading: boolean;
  itemVariantGroupsError: HttpError;
};

export type ItemVariantGroupActionType = ItemVariantGroupStateType & {
  type: ItemVariantGroupActionTypes;
};

export const initialState: ItemVariantGroupStateType = {
  itemVariantGroupCreateLoading: false,
  itemVariantGroupCreateError: null,
  itemVariantGroupCreateSuccess: false,
  itemVariantGroupUpdateLoading: false,
  itemVariantGroupUpdateError: null,
  itemVariantGroupUpdateSuccess: false,
  itemVariantGroupDeleteLoading: false,
  itemVariantGroupDeleteError: null,
  itemVariantGroupDeleteSuccess: false,
  itemVariantGroups: [],
  itemVariantGroupsLoading: true,
  itemVariantGroupsError: null,
};

const createItemVariantGroupStart = (
  state: ItemVariantGroupStateType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupCreateLoading: true,
  itemVariantGroupCreateSuccess: false,
  itemVariantGroupUpdateSuccess: false,
  itemVariantGroupDeleteSuccess: false,
});

const createItemVariantGroupSuccess = (
  state: ItemVariantGroupStateType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupCreateLoading: false,
  itemVariantGroupCreateError: null,
  itemVariantGroupCreateSuccess: true,
});

const createItemVariantGroupFail = (
  state: ItemVariantGroupStateType,
  action: ItemVariantGroupActionType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupCreateLoading: false,
  itemVariantGroupCreateError: action.itemVariantGroupCreateError,
});

const updateItemVariantGroupStart = (
  state: ItemVariantGroupStateType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupUpdateLoading: true,
  itemVariantGroupCreateSuccess: false,
  itemVariantGroupUpdateSuccess: false,
  itemVariantGroupDeleteSuccess: false,
});

const updateItemVariantGroupSuccess = (
  state: ItemVariantGroupStateType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupUpdateLoading: false,
  itemVariantGroupUpdateError: null,
  itemVariantGroupUpdateSuccess: true,
});

const updateItemVariantGroupFail = (
  state: ItemVariantGroupStateType,
  action: ItemVariantGroupActionType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupUpdateLoading: false,
  itemVariantGroupUpdateError: action.itemVariantGroupUpdateError,
});

const deleteItemVariantGroupStart = (
  state: ItemVariantGroupStateType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupDeleteLoading: true,
  itemVariantGroupCreateSuccess: false,
  itemVariantGroupUpdateSuccess: false,
  itemVariantGroupDeleteSuccess: false,
});

const deleteItemVariantGroupSuccess = (
  state: ItemVariantGroupStateType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupDeleteLoading: false,
  itemVariantGroupDeleteError: null,
  itemVariantGroupDeleteSuccess: true,
});

const deleteItemVariantGroupFail = (
  state: ItemVariantGroupStateType,
  action: ItemVariantGroupActionType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupDeleteLoading: false,
  itemVariantGroupDeleteError: action.itemVariantGroupDeleteError,
});

const fetchItemVariantGroupsStart = (
  state: ItemVariantGroupStateType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupsLoading: true,
});

const fetchItemVariantGroupsSuccess = (
  state: ItemVariantGroupStateType,
  action: ItemVariantGroupActionType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroups: action.itemVariantGroups,
  itemVariantGroupsLoading: false,
  itemVariantGroupsError: null,
});

const fetchItemVariantGroupsFail = (
  state: ItemVariantGroupStateType,
  action: ItemVariantGroupActionType,
): ItemVariantGroupStateType => ({
  ...state,
  itemVariantGroupsError: action.itemVariantGroupsError,
  itemVariantGroupsLoading: false,
});

const resetItemVariantGroupStore = (): ItemVariantGroupStateType => ({
  itemVariantGroupCreateLoading: false,
  itemVariantGroupCreateError: null,
  itemVariantGroupCreateSuccess: false,
  itemVariantGroupUpdateLoading: false,
  itemVariantGroupUpdateError: null,
  itemVariantGroupUpdateSuccess: false,
  itemVariantGroupDeleteLoading: false,
  itemVariantGroupDeleteError: null,
  itemVariantGroupDeleteSuccess: false,
  itemVariantGroups: [],
  itemVariantGroupsLoading: true,
  itemVariantGroupsError: null,
});

const reducer = (state = initialState, action: ItemVariantGroupActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_ITEM_VARIANT_GROUP_START:
      return createItemVariantGroupStart(state);
    case actionTypes.CREATE_ITEM_VARIANT_GROUP_SUCCESS:
      return createItemVariantGroupSuccess(state);
    case actionTypes.CREATE_ITEM_VARIANT_GROUP_FAIL:
      return createItemVariantGroupFail(state, action);
    case actionTypes.UPDATE_ITEM_VARIANT_GROUP_START:
      return updateItemVariantGroupStart(state);
    case actionTypes.UPDATE_ITEM_VARIANT_GROUP_SUCCESS:
      return updateItemVariantGroupSuccess(state);
    case actionTypes.UPDATE_ITEM_VARIANT_GROUP_FAIL:
      return updateItemVariantGroupFail(state, action);
    case actionTypes.DELETE_ITEM_VARIANT_GROUP_START:
      return deleteItemVariantGroupStart(state);
    case actionTypes.DELETE_ITEM_VARIANT_GROUP_SUCCESS:
      return deleteItemVariantGroupSuccess(state);
    case actionTypes.DELETE_ITEM_VARIANT_GROUP_FAIL:
      return deleteItemVariantGroupFail(state, action);
    case actionTypes.FETCH_ITEM_VARIANT_GROUPS_START:
      return fetchItemVariantGroupsStart(state);
    case actionTypes.FETCH_ITEM_VARIANT_GROUPS_SUCCESS:
      return fetchItemVariantGroupsSuccess(state, action);
    case actionTypes.FETCH_ITEM_VARIANT_GROUPS_FAIL:
      return fetchItemVariantGroupsFail(state, action);
    case actionTypes.RESET_ITEM_VARIANT_GROUP_STORE:
      return resetItemVariantGroupStore();
    default:
      return state;
  }
};

export default reducer;
