import React, { useEffect, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import styles from './BookTimeReservation.module.scss';
import { CartItem } from '../../../domain/Cart';
import moment from 'moment';
import { getFormattedSlotEnd } from '../../../utility/calendar/calendar';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { User } from '../../../domain/User';
import Button from '../../../common/Button/Button';
import cx from 'classnames';
import { DirectPaymentRequest } from '../../../store/payment/service';
import { ReservationsCreateRequest } from '../../../store/reservation/service';
import { PaymentType } from '../../../domain/Payment';
import * as paymentService from '../../../store/payment/service';
import * as reservationService from '../../../store/reservation/service';
import { Reservation } from '../../../domain/Reservation';
import { resetCartItems } from '../../../store/user/actions';
import { navigateToUrl } from '../../../utility/url/urlHelper';

type Props = {
  cartItems: CartItem[];
  user: User | null;
  reservationsCreateSuccess: boolean;
  reservationPaymentCreateSuccess: boolean;
  isLoading: boolean;
  onDirectPaymentCreate: (
    params: DirectPaymentRequest,
    intl: IntlShape,
  ) => void;
  onReservationsCreate: (
    params: ReservationsCreateRequest,
    intl: IntlShape,
  ) => void;
  isDirectPayment: boolean;
  reservationPaymentSuccessRedirect: string | null;
  onSuccess?: () => void;
  isSuccess?: boolean;
  createdReservations: Reservation[] | null;
  onResetCartItems: () => void;
};

export const BookTimeReservation = ({
  cartItems,
  reservationsCreateSuccess,
  reservationPaymentCreateSuccess,
  isLoading,
  onDirectPaymentCreate,
  onReservationsCreate,
  isDirectPayment,
  reservationPaymentSuccessRedirect,
  onSuccess,
  isSuccess,
  createdReservations,
  onResetCartItems,
}: Props) => {
  const intl = useIntl();

  const getFinalPrice = () => {
    if (!isDirectPayment) {
      return cartItems.reduce(
        (totalPrice, slot) => totalPrice + slot.slot.priceWithCredits,
        0,
      );
    }

    return cartItems.reduce(
      (totalPrice, slot) => totalPrice + slot.slot.price,
      0,
    );
  };

  const reserveButtonClickHandler = () => {
    const reservations = cartItems.map((cartItem) => ({
      slotId: cartItem.slot.id,
      finalPrice: isDirectPayment
        ? cartItem.slot.price
        : cartItem.slot.priceWithCredits,
      isPurchasedWithCredits: !isDirectPayment,
    }));

    onReservationsCreate({ reservations: reservations }, intl);
  };

  useEffect(() => {
    if (reservationsCreateSuccess && isDirectPayment) {
      onDirectPaymentCreate(
        {
          finalPrice: getFinalPrice(),
          paymentType: PaymentType.RESERVATION,
          reservationIds: createdReservations?.map(
            (reservation) => reservation.id,
          ),
        },
        intl,
      );
    }
    localStorage.setItem('currentPage', window.location.pathname);
  }, [reservationsCreateSuccess]);

  useEffect(() => {
    if (reservationPaymentCreateSuccess && reservationPaymentSuccessRedirect) {
      onSuccess?.();
      onResetCartItems();
      navigateToUrl(reservationPaymentSuccessRedirect);
    }
  }, [reservationPaymentCreateSuccess]);

  const isSuccessPurchaseContentVisible = useMemo(() => {
    return (
      (reservationsCreateSuccess && !isDirectPayment) || isSuccess !== undefined
    );
  }, [reservationsCreateSuccess, isDirectPayment]);

  const getSubtitle = () => {
    if (!isDirectPayment || isSuccess) {
      return translate(intl, 'BOOK_TIME.CONFIRMATION_MODAL_SUB_TITLE');
    }

    return translate(intl, 'BOOK_TIME.CONFIRMATION_MODAL_SUB_TITLE_FAILED');
  };

  const getStatement = () => {
    if (!isDirectPayment || isSuccess) {
      return translate(intl, 'BOOK_TIME.CONFIRMATION_MODAL_STATEMENT');
    }
    return translate(intl, 'BOOK_TIME.CONFIRMATION_MODAL_STATEMENT_FAILED');
  };

  return (
    <>
      {!isSuccessPurchaseContentVisible && (
        <div className={styles.content}>
          <p className={styles.contentTitle}>
            {translate(intl, 'BOOK_TIME.CONFIRMATION_MODAL_TITLE')}
          </p>

          <div
            className={cx(
              styles.contentBookedTimesContainer,
              styles.confirmationContainer,
            )}
          >
            <p className={styles.reservationTitle}>
              {`${translate(intl, 'BOOK_TIME.SELECTED_TIMES_MESSAGE')}:`}
            </p>
            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map((cartItem) => (
                <div
                  key={cartItem.uuid}
                  className={styles.contentBookedTimestext}
                >
                  <p>
                    {moment(cartItem.slot?.date, 'YYYY-MM-DD').format('MM')}-
                    {moment(cartItem.slot?.date, 'YYYY-MM-DD').format('DD')} (
                    {moment(cartItem.slot?.date, 'YYYY-MM-DD').format('dddd')})
                  </p>
                  <p>
                    {`${cartItem.slot.time} - ${getFormattedSlotEnd(
                      cartItem.slot,
                    )}`}
                  </p>
                </div>
              ))}
            <p className={styles.reservationCost}>
              {`${translate(
                intl,
                'PURCHASE_MODAL.ORDER_ITEMS_PRICE',
              )} ${getFinalPrice()} ${translate(intl, 'PURCHASE_MODAL.EUR')}`}
            </p>
          </div>
          <div className={styles.confirm}>
            <Button
              onClick={reserveButtonClickHandler}
              isLoadingButton
              isLoading={isLoading}
            >
              <span>{translate(intl, 'BOOK_TIME.RESERVE_CTA_TITLE')}</span>
            </Button>
          </div>
        </div>
      )}

      {isSuccessPurchaseContentVisible && (
        <div className={styles.content}>
          <p className={styles.contentTitle}>
            {translate(intl, 'BOOK_TIME.CONFIRMATION_MODAL_TITLE')}
          </p>
          <p className={styles.contentSubTitle}>{getSubtitle()}</p>
          <p className={styles.contentStatement}>{getStatement()}</p>
          {cartItems &&
            cartItems.length > 0 &&
            cartItems.map((cartItem) => (
              <div
                key={cartItem.uuid}
                className={styles.contentBookedTimesContainer}
              >
                <div className={styles.contentBookedTimestext}>
                  <p>
                    {moment(cartItem.slot?.date, 'YYYY-MM-DD').format('MM')}-
                    {moment(cartItem.slot?.date, 'YYYY-MM-DD').format('DD')} (
                    {moment(cartItem.slot?.date, 'YYYY-MM-DD').format('dddd')})
                  </p>
                  <p>
                    {`${cartItem.slot.time} - ${getFormattedSlotEnd(
                      cartItem.slot,
                    )}`}
                  </p>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.user.currentUser,
  reservationsCreateSuccess: state.reservation.reservationsCreateSuccess,
  reservationPaymentCreateSuccess: state.payment.directPaymentCreateSuccess,
  isLoading:
    state.payment.directPaymentCreateLoading ||
    state.reservation.reservationsCreateLoading,
  reservationPaymentSuccessRedirect: state.payment.directPaymentSuccessRedirect,
  createdReservations: state.reservation.createdReservations,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onReservationsCreate: (params: ReservationsCreateRequest, intl: IntlShape) =>
    dispatch(reservationService.createReservations(params, intl)),
  onDirectPaymentCreate: (params: DirectPaymentRequest, intl: IntlShape) =>
    dispatch(paymentService.createDirectPayment(params, intl)),
  onResetCartItems: () => dispatch(resetCartItems()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookTimeReservation);
