import axios from '../../config/Axios/axios-instance';
import {
  createOrderFail,
  createOrderStart,
  createOrderSuccess,
  fetchOrdersFail,
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchUserOrdersFail,
  fetchUserOrdersStart,
  fetchUserOrdersSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from 'src/hooks/useList/useList';

const API_URL = '/orders';

export type OrderCreateRequest = {
  itemId: number;
  userId: number;
  quantity: number;
  itemVariantId?: number;
  isDirectPayment: boolean;
};

export const createOrder =
  (inputs: OrderCreateRequest) => (dispatch: Dispatch) => {
    dispatch(createOrderStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createOrderSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createOrderFail(err.response.data.message));
      });
  };

export const fetchOrders = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchOrdersStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchOrdersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchOrdersFail(err.response.data.error));
    });
};

export const fetchUserOrders = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUserOrdersStart());
  return axios
    .get(`${API_URL}/user-orders`, { params })
    .then((response) => {
      dispatch(fetchUserOrdersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserOrdersFail(err.response.data.error));
    });
};
