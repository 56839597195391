import * as actionTypes from './actionTypes';
import { ItemActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Item, RelatedShopItemsList } from '../../domain/Item';
import { ListResults } from '../../common/List/List';

export type ItemStateType = {
  itemsList: ListResults<Item> | null;
  itemsLoading: boolean;
  itemsError: HttpError;
  itemsListUpdateNeeded: boolean;
  itemCreateLoading: boolean;
  itemCreateError: HttpError;
  itemCreateSuccess: boolean;
  createdItem: Item | null;
  itemUpdateLoading: boolean;
  itemUpdateError: HttpError;
  itemUpdateSuccess: boolean;
  itemDeleteLoading: boolean;
  itemDeleteError: HttpError;
  itemDeleteSuccess: boolean;
  item: Item | null;
  itemLoading: boolean;
  itemError: HttpError;
  publicShopItems: ListResults<Item> | null;
  publicShopItemsLoading: boolean;
  publicShopItemsError: HttpError;
  publicShopItem: Item | null;
  publicShopItemLoading: boolean;
  publicShopItemError: HttpError;
  relatedShopItems: RelatedShopItemsList | null;
  relatedShopItemsLoading: boolean;
  relatedShopItemsError: HttpError;
  publicShopItemUpdateNeeded: boolean;
};

export type ItemActionType = ItemStateType & {
  type: ItemActionTypes;
};

export const initialState: ItemStateType = {
  itemsList: null,
  itemsLoading: true,
  itemsError: null,
  itemsListUpdateNeeded: false,
  itemCreateLoading: false,
  itemCreateError: null,
  itemCreateSuccess: false,
  createdItem: null,
  itemUpdateLoading: false,
  itemUpdateError: null,
  itemUpdateSuccess: false,
  itemDeleteLoading: false,
  itemDeleteError: null,
  itemDeleteSuccess: false,
  item: null,
  itemLoading: false,
  itemError: null,
  relatedShopItems: null,
  relatedShopItemsLoading: false,
  relatedShopItemsError: null,
  publicShopItem: null,
  publicShopItemLoading: false,
  publicShopItemError: null,
  publicShopItems: null,
  publicShopItemsLoading: false,
  publicShopItemsError: null,
  publicShopItemUpdateNeeded: false,
};

const fetchItemsStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemsLoading: true,
});

const fetchItemsSuccess = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemsList: action.itemsList,
  itemsLoading: false,
  itemsError: null,
  itemsListUpdateNeeded: false,
});

const fetchItemsFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemsError: action.itemsError,
  itemsLoading: false,
});

const fetchItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemLoading: true,
  itemCreateSuccess: false,
  itemCreateError: null,
});

const fetchItemSuccess = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  item: action.item,
  itemLoading: false,
  itemError: null,
});

const fetchItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemError: action.itemError,
  itemLoading: false,
});

const createItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemCreateLoading: true,
});

const createItemSuccess = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemCreateLoading: false,
  itemCreateError: null,
  itemCreateSuccess: true,
  createdItem: action.createdItem,
});

const createItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemCreateLoading: false,
  itemCreateError: action.itemCreateError,
});

const updateItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemUpdateLoading: true,
  itemUpdateSuccess: false,
});

const updateItemSuccess = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemUpdateLoading: false,
  itemUpdateError: null,
  itemUpdateSuccess: true,
});

const updateItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemUpdateLoading: false,
  itemUpdateError: action.itemUpdateError,
});

const deleteItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemDeleteLoading: true,
});

const deleteItemSuccess = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemDeleteLoading: false,
  itemDeleteError: null,
  itemDeleteSuccess: true,
  itemsListUpdateNeeded: true,
});

const deleteItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  itemDeleteLoading: false,
  itemDeleteError: action.itemDeleteError,
});

const fetchRelatedShopItemsStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  relatedShopItemsLoading: true,
});

const fetchRelatedShopItemsSuccess = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  relatedShopItems: action.relatedShopItems,
  relatedShopItemsLoading: false,
});

const fetchRelatedShopItemsFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  relatedShopItemsLoading: false,
  relatedShopItemsError: action.relatedShopItemsError,
});

const resetRelatedShopItems = (state: ItemStateType): ItemStateType => ({
  ...state,
  relatedShopItems: null,
});

const fetchPublicShopItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  publicShopItemLoading: true,
  publicShopItemUpdateNeeded: false,
});

const fetchPublicShopItemSuccess = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  publicShopItem: action.publicShopItem,
  publicShopItemLoading: false,
  publicShopItemUpdateNeeded: true,
});

const fetchPublicShopItemFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  publicShopItemLoading: false,
  publicShopItemError: action.publicShopItemError,
  publicShopItemUpdateNeeded: false,
});

const fetchPublicShopItemsStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  publicShopItemsLoading: true,
});

const fetchPublicShopItemsSuccess = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  publicShopItems: action.publicShopItems,
  publicShopItemsLoading: false,
});

const fetchPublicShopItemsFail = (
  state: ItemStateType,
  action: ItemActionType,
): ItemStateType => ({
  ...state,
  publicShopItemsLoading: false,
  publicShopItemsError: action.publicShopItemsError,
});

const resetItemStore = (state: ItemStateType): ItemStateType => ({
  ...initialState,
});

const completelyResetItemStore = (): ItemStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ItemActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ITEMS_START:
      return fetchItemsStart(state);
    case actionTypes.FETCH_ITEMS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case actionTypes.FETCH_ITEMS_FAIL:
      return fetchItemsFail(state, action);
    case actionTypes.FETCH_ITEM_START:
      return fetchItemStart(state);
    case actionTypes.FETCH_ITEM_SUCCESS:
      return fetchItemSuccess(state, action);
    case actionTypes.FETCH_ITEM_FAIL:
      return fetchItemFail(state, action);
    case actionTypes.CREATE_ITEM_START:
      return createItemStart(state);
    case actionTypes.CREATE_ITEM_SUCCESS:
      return createItemSuccess(state, action);
    case actionTypes.CREATE_ITEM_FAIL:
      return createItemFail(state, action);
    case actionTypes.UPDATE_ITEM_START:
      return updateItemStart(state);
    case actionTypes.UPDATE_ITEM_SUCCESS:
      return updateItemSuccess(state);
    case actionTypes.UPDATE_ITEM_FAIL:
      return updateItemFail(state, action);
    case actionTypes.DELETE_ITEM_START:
      return deleteItemStart(state);
    case actionTypes.DELETE_ITEM_SUCCESS:
      return deleteItemSuccess(state);
    case actionTypes.DELETE_ITEM_FAIL:
      return deleteItemFail(state, action);
    case actionTypes.RESET_RELATED_SHOP_ITEM_STORE:
      return resetRelatedShopItems(state);
    case actionTypes.FETCH_PUBLIC_SHOP_ITEMS_START:
      return fetchPublicShopItemsStart(state);
    case actionTypes.FETCH_PUBLIC_SHOP_ITEMS_SUCCESS:
      return fetchPublicShopItemsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SHOP_ITEMS_FAIL:
      return fetchPublicShopItemsFail(state, action);
    case actionTypes.FETCH_PUBLIC_SHOP_ITEM_START:
      return fetchPublicShopItemStart(state);
    case actionTypes.FETCH_PUBLIC_SHOP_ITEM_SUCCESS:
      return fetchPublicShopItemSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SHOP_ITEM_FAIL:
      return fetchPublicShopItemFail(state, action);
    case actionTypes.FETCH_RELATED_SHOP_ITEMS_START:
      return fetchRelatedShopItemsStart(state);
    case actionTypes.FETCH_RELATED_SHOP_ITEMS_SUCCESS:
      return fetchRelatedShopItemsSuccess(state, action);
    case actionTypes.FETCH_RELATED_SHOP_ITEMS_FAIL:
      return fetchRelatedShopItemsFail(state, action);
    case actionTypes.RESET_ITEM_STORE:
      return resetItemStore(state);
    case actionTypes.LOGOUT:
      return completelyResetItemStore();
    default:
      return state;
  }
};

export default reducer;
