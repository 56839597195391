import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';

const LoginPage = React.lazy(
  () => import('../../pages/Public/LoginPage/LoginPage'),
);

const RegistrationPage = React.lazy(
  () => import('../../pages/Public/RegistrationPage/RegistrationPage'),
);

const RegistrationConfirmationPage = React.lazy(
  () =>
    import(
      '../../pages/Public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);

const PasswordRemindPage = React.lazy(
  () => import('../../pages/Public/PasswordRemindPage/PasswordRemindPage'),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const BookTimePage = React.lazy(
  () => import('../../pages/Public/BookTimePage/BookTimePage'),
);

const ProfilePage = React.lazy(
  () => import('../../pages/Public/ProfilePage/ProfilePage'),
);

const LocationsListPage = React.lazy(
  () =>
    import('../../pages/Admin/Location/LocationsListPage/LocationsListPage'),
);
const LocationCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/Location/LocationCreatePage/LocationCreatePage'),
);
const LocationEditPage = React.lazy(
  () => import('../../pages/Admin/Location/LocationEditPage/LocationEditPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);
const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);
const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const CategoryListPage = React.lazy(
  () => import('../../pages/Admin/Category/CategoryListPage/CategoryListPage'),
);

const CategoryCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/Category/CategoryCreatePage/CategoryCreatePage'),
);

const CategoryEditPage = React.lazy(
  () => import('../../pages/Admin/Category/CategoryEditPage/CategoryEditPage'),
);

const ItemCreatePage = React.lazy(
  () => import('../../pages/Admin/Item/ItemCreatePage/ItemCreatePage'),
);

const ItemEditPage = React.lazy(
  () => import('../../pages/Admin/Item/ItemEditPage/ItemEditPage'),
);

const ItemsListPage = React.lazy(
  () => import('../../pages/Admin/Item/ItemsListPage/ItemsListPage'),
);

const EurPackageCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Admin/EurPackage/EurPackageCreatePage/EurPackageCreatePage'
    ),
);

const EurPackageEditPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/EurPackage/EurPackageEditPage/EurPackageEditPage'
    ),
);

const EurPackageListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/EurPackage/EurPackageListPage/EurPackageListPage'
    ),
);

const NewsPostCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/NewsPost/NewsPostCreatePage/NewsPostCreatePage'),
);

const NewsPostEditPage = React.lazy(
  () => import('../../pages/Admin/NewsPost/NewsPostEditPage/NewsPostEditPage'),
);

const NewsPostListPage = React.lazy(
  () => import('../../pages/Admin/NewsPost/NewsPostListPage/NewsPostListPage'),
);

const SettingsPage = React.lazy(
  () => import('../../pages/Admin/Settings/SettingsPage/SettingsPage'),
);

const ReservationsPage = React.lazy(
  () => import('../../pages/Admin/Reservations/Reservations'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const OrderListPage = React.lazy(
  () => import('../../pages/Admin/Order/OrderListPage/OrderListPage'),
);

const PaymentResponse = React.lazy(
  () => import('../../pages/Public/PaymentPage/PaymentResponse'),
);

const PaymentListPage = React.lazy(
  () => import('../../pages/Admin/Payment/PaymentListPage/PaymentListPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  currentUser: User | null;
};

const ALLOWED_LOCALES = ['en', 'lt'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  onLanguagesInit,
  selectedLocale,
  onSelectLocale,
  languages,
  currentUser,
}: Props) => {
  const locale =
    window.location?.pathname?.substring(1)?.split('/')?.[0] ?? DEFAULT_LOCALE;
  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageFetch(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(parsedLocale);
    onLanguagesInit();
    onSelectLocale(parsedLocale);
  }, [parsedLocale]);

  const getCurrentLanguage = () => {
    const foundLanguage = languages?.find(
      (singleLanguage) => singleLanguage.locale === selectedLocale,
    );

    return foundLanguage ?? language;
  };

  const mappedTranslations = getCurrentLanguage()?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const adminRoutes =
    currentUser &&
    currentUser.role === Roles.ADMIN &&
    isAuthenticated &&
    jwtToken;
  const userRoutes = isAuthenticated && currentUser;
  const publicRoutes = currentUser || !currentUser;

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="lt"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout>
                <Routes>
                  {adminRoutes && (
                    <>
                      <Route
                        path={routes.admin}
                        element={<Navigate replace to={routes.reservations} />}
                      />
                      <Route
                        path={routes.locations.create}
                        element={<LocationCreatePage />}
                      />
                      <Route
                        path={routes.locations.edit}
                        element={<LocationEditPage />}
                      />
                      <Route
                        path={routes.locations.list}
                        element={<LocationsListPage />}
                      />
                      <Route
                        path={routes.users.create}
                        element={<UserCreatePage />}
                      />
                      <Route
                        path={routes.users.edit}
                        element={<UserEditPage />}
                      />
                      <Route
                        path={routes.users.list}
                        element={<UsersListPage />}
                      />
                      <Route
                        path={routes.categories.list}
                        element={<CategoryListPage />}
                      />
                      <Route
                        path={routes.categories.create}
                        element={<CategoryCreatePage />}
                      />
                      <Route
                        path={routes.categories.edit}
                        element={<CategoryEditPage />}
                      />
                      <Route
                        path={routes.items.create}
                        element={<ItemCreatePage />}
                      />
                      <Route
                        path={routes.items.edit}
                        element={<ItemEditPage />}
                      />
                      <Route
                        path={routes.items.list}
                        element={<ItemsListPage />}
                      />
                      <Route
                        path={routes.eurPackages.create}
                        element={<EurPackageCreatePage />}
                      />
                      <Route
                        path={routes.eurPackages.edit}
                        element={<EurPackageEditPage />}
                      />
                      <Route
                        path={routes.eurPackages.list}
                        element={<EurPackageListPage />}
                      />
                      <Route
                        path={routes.newsPosts.create}
                        element={<NewsPostCreatePage />}
                      />
                      <Route
                        path={routes.newsPosts.edit}
                        element={<NewsPostEditPage />}
                      />
                      <Route
                        path={routes.newsPosts.list}
                        element={<NewsPostListPage />}
                      />
                      <Route
                        path={routes.settings}
                        element={<SettingsPage />}
                      />
                      <Route
                        path={routes.reservations}
                        element={<ReservationsPage />}
                      />
                      <Route
                        path={routes.translations}
                        element={<TranslationsPage />}
                      />
                      <Route
                        path={routes.orders.list}
                        element={<OrderListPage />}
                      />
                      <Route
                        path={routes.payment.list}
                        element={<PaymentListPage />}
                      />
                    </>
                  )}

                  {userRoutes && (
                    <>
                      <Route
                        path={routes.profile.details}
                        element={<ProfilePage />}
                      />
                      <Route
                        path={routes.profile.balance.paymentResponse}
                        element={<PaymentResponse />}
                      />
                    </>
                  )}

                  {publicRoutes && (
                    <>
                      <Route
                        path={routes.bookTime}
                        element={<BookTimePage />}
                      />
                      <Route path={routes.login} element={<LoginPage />} />
                      <Route
                        path={routes.remind}
                        element={<PasswordRemindPage />}
                      />
                      <Route
                        path={routes.resetPassword}
                        element={<PasswordResetPage />}
                      />
                      <Route
                        path={routes.register}
                        element={<RegistrationPage />}
                      />
                      <Route
                        path={routes.registrationConfirmation}
                        element={<RegistrationConfirmationPage />}
                      />
                      <Route path={routes.homepage} element={<HomePage />} />
                      <Route
                        path={routes.dynamicPage}
                        element={<DynamicPage />}
                      />
                      <Route
                        path={routes.dynamicPageWithContent}
                        element={<DynamicPage />}
                      />
                    </>
                  )}

                  <Route path="*" element={<Navigate to={routes.homepage} />} />
                </Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  loginLoading: state.auth.loginGoogleLoading,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
