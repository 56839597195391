import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  createTopUpFail,
  createTopUpStart,
  createTopUpSuccess,
  createDirectPaymentFail,
  createDirectPaymentStart,
  createDirectPaymentSuccess,
  fetchPaymentFail,
  fetchPaymentListFail,
  fetchPaymentListStart,
  fetchPaymentListSuccess,
  fetchPaymentStart,
  fetchPaymentSuccess,
  exportPaymentsStart,
  exportPaymentsSuccess,
  exportPaymentsFail,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { ListParams } from '../../hooks/useList/useList';
import { IntlShape } from 'react-intl';
import { PaymentType } from '../../domain/Payment';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/payments';

export type TopUpCreateRequest = {
  eurPackageId?: number;
  customEurAmount?: number;
  paymentType: PaymentType;
};

export type DirectPaymentRequest = {
  finalPrice: number;
  paymentType: PaymentType;
  reservationIds?: number[];
  createdOrderId?: number;
};

export type PaymentListParams = ListParams & {
  isPaid?: boolean;
  fromDate?: string;
  toDate?: string;
};

export type ExportPaymentsRequest = {
  fromDate: string;
  toDate: string;
};

export const fetchPayment = (paymentNumber: string) => (dispatch: Dispatch) => {
  dispatch(fetchPaymentStart());
  return axios
    .get(`${API_URL}/${paymentNumber}`)
    .then((response) => {
      dispatch(fetchPaymentSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPaymentFail(err.response.data.message));
      showToast(err.response.data.message, 'error');
    });
};

export const fetchPaymentList =
  (params: PaymentListParams) => (dispatch: Dispatch) => {
    dispatch(fetchPaymentListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchPaymentListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPaymentListFail(err?.response?.data?.message));
      });
  };

export const createTopUp =
  (inputs: TopUpCreateRequest) => (dispatch: Dispatch) => {
    dispatch(createTopUpStart());
    return axios
      .post('/payments', {
        ...inputs,
      })
      .then((response) => {
        dispatch(createTopUpSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createTopUpFail(err.response.data.message));
      });
  };

export const createDirectPayment =
  (body: DirectPaymentRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createDirectPaymentStart());
    return axios
      .post('/payments', {
        ...body,
      })
      .then((response) => {
        dispatch(createDirectPaymentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createDirectPaymentFail(err?.response?.data?.message));
      });
  };

export const exportPayments =
  (inputs: ExportPaymentsRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(exportPaymentsStart());
    return axios
      .post(`${API_URL}/export`, { ...inputs })
      .then((response) => {
        dispatch(exportPaymentsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(exportPaymentsFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };
