export const FETCH_USER_BALANCE_HISTORY_LIST_START =
  'FETCH_USER_BALANCE_HISTORY_LIST_START';
export const FETCH_USER_BALANCE_HISTORY_LIST_SUCCESS =
  'FETCH_USER_BALANCE_HISTORY_LIST_SUCCESS';
export const FETCH_USER_BALANCE_HISTORY_LIST_FAIL =
  'FETCH_USER_BALANCE_HISTORY_LIST_FAIL';

export const RESET_BALANCE_HISTORY_STORE = 'RESET_BALANCE_HISTORY_STORE';

export const LOGOUT = 'LOGOUT';

export type BalanceHistoryActionTypes =
  | typeof FETCH_USER_BALANCE_HISTORY_LIST_START
  | typeof FETCH_USER_BALANCE_HISTORY_LIST_SUCCESS
  | typeof FETCH_USER_BALANCE_HISTORY_LIST_FAIL
  | typeof RESET_BALANCE_HISTORY_STORE
  | typeof LOGOUT;
