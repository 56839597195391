import React, { useState } from 'react';
import styles from './LanguageSwitcher.module.scss';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { selectLocale } from '../../store/auth/actions';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import { Locale } from '../../domain/Translation';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

type Props = {
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  isLightNavigation: boolean;
  handlePopoverState: () => void;
  isPopoverOpen: boolean;
};

const LanguageSwitcher = ({
  onSelectLocale,
  selectedLocale,
  isLightNavigation,
  handlePopoverState,
  isPopoverOpen,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const LANGUAGES = [
    {
      label: translate(intl, 'LANGUAGES.EN'),
      value: 'en',
    },
    {
      label: translate(intl, 'LANGUAGES.LT'),
      value: 'lt',
    },
  ];

  const selectedLocaleOption = LANGUAGES.find(
    (locale) => locale.value === selectedLocale,
  );

  const handleClick = (locale: Locale) => {
    if (selectedLocale === locale) {
      return handlePopoverState();
    }

    onSelectLocale(locale);
    handlePopoverState();

    navigate(location.pathname.replace(`/${selectedLocale}/`, `/${locale}/`));
  };

  const renderActions = () => {
    return LANGUAGES.map((language, index) => (
      <button
        className={styles.popoverListItem}
        key={index.toString()}
        onClick={() => handleClick(language.value as Locale)}
      >
        {language?.label}
      </button>
    ));
  };

  return (
    <>
      <div
        className={cx(styles.switcherButton, {
          [styles.lightSwitcherButton]: isLightNavigation,
        })}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          handlePopoverState();
        }}
      >
        {selectedLocaleOption?.label}
      </div>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => handlePopoverState()}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>{renderActions()}</ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
