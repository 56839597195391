import * as actionTypes from './actionTypes';
import { ItemVariantActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ItemVariantStateType = {
  itemVariantCreateLoading: boolean;
  itemVariantCreateError: HttpError;
  itemVariantUpdateLoading: boolean;
  itemVariantUpdateError: HttpError;
  itemVariantDeleteLoading: boolean;
  itemVariantDeleteError: HttpError;
  itemVariantCreateSuccess: boolean;
  itemVariantUpdateSuccess: boolean;
  itemVariantDeleteSuccess: boolean;
  itemVariantsPriorityUpdateLoading: boolean;
  itemVariantsPriorityUpdateError: HttpError;
  itemVariantsPriorityUpdateSuccess: boolean;
};

export type ItemVariantActionType = ItemVariantStateType & {
  type: ItemVariantActionTypes;
};

export const initialState: ItemVariantStateType = {
  itemVariantCreateLoading: false,
  itemVariantCreateError: null,
  itemVariantUpdateLoading: false,
  itemVariantUpdateError: null,
  itemVariantDeleteLoading: false,
  itemVariantDeleteError: null,
  itemVariantCreateSuccess: false,
  itemVariantUpdateSuccess: false,
  itemVariantDeleteSuccess: false,
  itemVariantsPriorityUpdateLoading: false,
  itemVariantsPriorityUpdateError: null,
  itemVariantsPriorityUpdateSuccess: false,
};

const createItemVariantStart = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantCreateLoading: true,
  itemVariantCreateSuccess: false,
  itemVariantUpdateSuccess: false,
  itemVariantDeleteSuccess: false,
  itemVariantsPriorityUpdateSuccess: false,
});

const createItemVariantSuccess = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantCreateLoading: false,
  itemVariantCreateError: null,
  itemVariantCreateSuccess: true,
});

const createItemVariantFail = (
  state: ItemVariantStateType,
  action: ItemVariantActionType,
): ItemVariantStateType => ({
  ...state,
  itemVariantCreateLoading: false,
  itemVariantCreateError: action.itemVariantCreateError,
});

const updateItemVariantStart = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantUpdateLoading: true,
  itemVariantCreateSuccess: false,
  itemVariantUpdateSuccess: false,
  itemVariantDeleteSuccess: false,
  itemVariantsPriorityUpdateSuccess: false,
});

const updateItemVariantSuccess = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantUpdateLoading: false,
  itemVariantUpdateError: null,
  itemVariantUpdateSuccess: true,
});

const updateItemVariantFail = (
  state: ItemVariantStateType,
  action: ItemVariantActionType,
): ItemVariantStateType => ({
  ...state,
  itemVariantUpdateLoading: false,
  itemVariantUpdateError: action.itemVariantUpdateError,
});

const deleteItemVariantStart = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantDeleteLoading: true,
  itemVariantCreateSuccess: false,
  itemVariantUpdateSuccess: false,
  itemVariantDeleteSuccess: false,
  itemVariantsPriorityUpdateSuccess: false,
});

const deleteItemVariantSuccess = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantDeleteLoading: false,
  itemVariantDeleteError: null,
  itemVariantDeleteSuccess: true,
});

const deleteItemVariantFail = (
  state: ItemVariantStateType,
  action: ItemVariantActionType,
): ItemVariantStateType => ({
  ...state,
  itemVariantDeleteLoading: false,
  itemVariantDeleteError: action.itemVariantDeleteError,
});

const updateItemVariantsPriorityStart = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantsPriorityUpdateLoading: true,
  itemVariantCreateSuccess: false,
  itemVariantUpdateSuccess: false,
  itemVariantDeleteSuccess: false,
  itemVariantsPriorityUpdateSuccess: false,
});

const updateItemVariantsPrioritySuccess = (
  state: ItemVariantStateType,
): ItemVariantStateType => ({
  ...state,
  itemVariantsPriorityUpdateError: null,
  itemVariantsPriorityUpdateSuccess: true,
});

const updateItemVariantsPriorityFail = (
  state: ItemVariantStateType,
  action: ItemVariantActionType,
): ItemVariantStateType => ({
  ...state,
  itemVariantsPriorityUpdateLoading: false,
  itemVariantsPriorityUpdateError: action.itemVariantsPriorityUpdateError,
});

const resetItemVariantStore = (): ItemVariantStateType => ({
  itemVariantCreateLoading: false,
  itemVariantCreateError: null,
  itemVariantUpdateLoading: false,
  itemVariantUpdateError: null,
  itemVariantDeleteLoading: false,
  itemVariantDeleteError: null,
  itemVariantCreateSuccess: false,
  itemVariantUpdateSuccess: false,
  itemVariantDeleteSuccess: false,
  itemVariantsPriorityUpdateLoading: false,
  itemVariantsPriorityUpdateError: null,
  itemVariantsPriorityUpdateSuccess: false,
});

const reducer = (state = initialState, action: ItemVariantActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_ITEM_VARIANT_START:
      return createItemVariantStart(state);
    case actionTypes.CREATE_ITEM_VARIANT_SUCCESS:
      return createItemVariantSuccess(state);
    case actionTypes.CREATE_ITEM_VARIANT_FAIL:
      return createItemVariantFail(state, action);
    case actionTypes.UPDATE_ITEM_VARIANT_START:
      return updateItemVariantStart(state);
    case actionTypes.UPDATE_ITEM_VARIANT_SUCCESS:
      return updateItemVariantSuccess(state);
    case actionTypes.UPDATE_ITEM_VARIANT_FAIL:
      return updateItemVariantFail(state, action);
    case actionTypes.DELETE_ITEM_VARIANT_START:
      return deleteItemVariantStart(state);
    case actionTypes.DELETE_ITEM_VARIANT_SUCCESS:
      return deleteItemVariantSuccess(state);
    case actionTypes.DELETE_ITEM_VARIANT_FAIL:
      return deleteItemVariantFail(state, action);
    case actionTypes.RESET_ITEM_VARIANT_STORE:
      return resetItemVariantStore();
    case actionTypes.UPDATE_ITEM_VARIANTS_PRIORITY_START:
      return updateItemVariantsPriorityStart(state);
    case actionTypes.UPDATE_ITEM_VARIANTS_PRIORITY_SUCCESS:
      return updateItemVariantsPrioritySuccess(state);
    case actionTypes.UPDATE_ITEM_VARIANTS_PRIORITY_FAIL:
      return updateItemVariantsPriorityFail(state, action);
    default:
      return state;
  }
};

export default reducer;
