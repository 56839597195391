import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Payment, PaymentType } from '../../domain/Payment';
import { PaymentActionTypes } from './actionTypes';
import { ListResults } from 'src/common/List/List';

export type PaymentStateType = {
  paymentLoading: boolean;
  payment: Payment | null;
  paymentError: HttpError;
  topUpCreateError: HttpError;
  topUpCreateLoading: boolean;
  topUpCreateSuccess: boolean;
  topUpSuccessRedirect: string | null;
  directPaymentCreateError: HttpError;
  directPaymentCreateLoading: boolean;
  directPaymentCreateSuccess: boolean;
  directPaymentSuccessRedirect: string | null;
  paymentList: ListResults<Payment> | null;
  paymentListLoading: boolean;
  paymentListError: HttpError;
  isSuccessPayment: boolean;
  paymentType: PaymentType | null;
  exportPayments: Payment[] | null;
  exportPaymentsLoading: boolean;
  exportPaymentsError: HttpError;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
  status: boolean;
};

export const initialState: PaymentStateType = {
  paymentLoading: false,
  payment: null,
  paymentError: null,
  topUpCreateError: null,
  topUpCreateLoading: false,
  topUpCreateSuccess: false,
  topUpSuccessRedirect: null,
  directPaymentCreateError: null,
  directPaymentCreateLoading: false,
  directPaymentCreateSuccess: false,
  directPaymentSuccessRedirect: null,
  paymentList: null,
  paymentListLoading: false,
  paymentListError: null,
  isSuccessPayment: false,
  paymentType: null,
  exportPaymentsError: null,
  exportPaymentsLoading: false,
  exportPayments: null,
};

const fetchPaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentLoading: true,
});

const fetchPaymentSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  payment: action.payment,
  paymentLoading: false,
  paymentError: null,
});

const fetchPaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentError: action.paymentError,
  paymentLoading: false,
});

const fetchPaymentListStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentListLoading: true,
});

const fetchPaymentListSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentList: action.paymentList,
  paymentListLoading: false,
});

const fetchPaymentListFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentListError: action.paymentListError,
  paymentListLoading: false,
});

const createTopUpStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  topUpCreateLoading: true,
});

const createTopUpSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  topUpCreateLoading: false,
  topUpCreateError: null,
  topUpCreateSuccess: true,
  topUpSuccessRedirect: action.topUpSuccessRedirect,
});

const createTopUpFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  topUpCreateLoading: false,
  topUpCreateError: action.topUpCreateError,
});

const createDirectPaymentStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  directPaymentCreateLoading: true,
});

const createDirectPaymentSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  directPaymentCreateLoading: false,
  directPaymentCreateError: null,
  directPaymentCreateSuccess: true,
  directPaymentSuccessRedirect: action.directPaymentSuccessRedirect,
});

const createDirectPaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  directPaymentCreateLoading: false,
  directPaymentCreateError: action.directPaymentCreateError,
});

const exportPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  exportPaymentsLoading: true,
});

const exportPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  exportPayments: action.exportPayments,
  exportPaymentsLoading: false,
  exportPaymentsError: null,
});

const exportPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  exportPaymentsError: action.exportPaymentsError,
  exportPaymentsLoading: false,
});

const resetExportPaymets = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  exportPayments: null,
  exportPaymentsLoading: false,
  exportPaymentsError: null,
});

const resetTopUpSuccessStatus = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  topUpCreateSuccess: false,
  topUpSuccessRedirect: null,
});

const resetDirectPaymentSuccessStatus = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  directPaymentCreateSuccess: false,
  directPaymentSuccessRedirect: null,
});

const setIsPaymentStatusSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  isSuccessPayment: action.status,
});

const setPaymentType = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentType: action.paymentType,
});

const resetPaymentStatus = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  payment: null,
  isSuccessPayment: false,
  paymentType: null,
});

const resetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const completelyResetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_START:
      return fetchPaymentStart(state);
    case actionTypes.FETCH_PAYMENT_SUCCESS:
      return fetchPaymentSuccess(state, action);
    case actionTypes.FETCH_PAYMENT_FAIL:
      return fetchPaymentFail(state, action);
    case actionTypes.FETCH_PAYMENT_LIST_START:
      return fetchPaymentListStart(state);
    case actionTypes.FETCH_PAYMENT_LIST_SUCCESS:
      return fetchPaymentListSuccess(state, action);
    case actionTypes.FETCH_PAYMENT_LIST_FAIL:
      return fetchPaymentListFail(state, action);
    case actionTypes.CREATE_TOP_UP_START:
      return createTopUpStart(state);
    case actionTypes.CREATE_TOP_UP_SUCCESS:
      return createTopUpSuccess(state, action);
    case actionTypes.CREATE_TOP_UP_FAIL:
      return createTopUpFail(state, action);
    case actionTypes.CREATE_DIRECT_PAYMENT_START:
      return createDirectPaymentStart(state);
    case actionTypes.CREATE_DIRECT_PAYMENT_SUCCESS:
      return createDirectPaymentSuccess(state, action);
    case actionTypes.CREATE_DIRECT_PAYMENT_FAIL:
      return createDirectPaymentFail(state, action);
    case actionTypes.EXPORT_PAYMENTS_START:
      return exportPaymentsStart(state);
    case actionTypes.EXPORT_PAYMENTS_SUCCESS:
      return exportPaymentsSuccess(state, action);
    case actionTypes.EXPORT_PAYMENTS_FAIL:
      return exportPaymentsFail(state, action);
    case actionTypes.RESET_EXPORT_PAYMENTS:
      return resetExportPaymets(state);
    case actionTypes.SET_IS_PAYMENT_STATUS_SUCCESS:
      return setIsPaymentStatusSuccess(state, action);
    case actionTypes.SET_PAYMENT_TYPE:
      return setPaymentType(state, action);
    case actionTypes.RESET_TOP_UP_SUCCESS_STATUS:
      return resetTopUpSuccessStatus(state);
    case actionTypes.RESET_DIRECT_PAYMENT_SUCCESS_STATUS:
      return resetDirectPaymentSuccessStatus(state);
    case actionTypes.RESET_PAYMENT_STATUS:
      return resetPaymentStatus(state);
    case actionTypes.RESET_PAYMENT_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return completelyResetPaymentStore();
    default:
      return state;
  }
};

export default reducer;
